// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hW1iWo4vLPalFv6se2GKFA\\=\\= {\n  margin-bottom: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/compensations/cost-modal-v2/styles.less"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF","sourcesContent":[".mb-0 {\n  margin-bottom: 0 !important;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mb-0": "hW1iWo4vLPalFv6se2GKFA=="
};
export default ___CSS_LOADER_EXPORT___;
