import { getCurrencyCodeByCountryCode } from '@retail/currency';
import { add, reduce } from 'lodash/fp';
import { useCallback, useMemo } from 'react';

import { CLAIM_COST_STATUSES } from '../../../constants';
import { Entity } from '../../types';

import { RetailClaimCostProjection } from '@/apollo/gql-types';

const getTotalCostAmount = reduce(
  (sum, { amountMinorUnits }) => add(sum, Number(amountMinorUnits) || 0),
  0,
);

export const getOrderSubclaimTotalCost = (
  costs: RetailClaimCostProjection[],
): number =>
  reduce<RetailClaimCostProjection, number>(
    (sum: number, { costMinorUnits, status }) => {
      if (status !== CLAIM_COST_STATUSES.COMPLETED) {
        return sum;
      }

      return sum + costMinorUnits;
    },
    0,
  )(costs);

export function useModalFormProps(claim: Entity) {
  const currencyCode = getCurrencyCodeByCountryCode(claim?.country);

  const totalCostMinorUnits = useMemo(
    () => getOrderSubclaimTotalCost(claim?.costs),
    [claim],
  );

  const initialValues = useMemo(() => {
    const isSingleSubclaim = claim?.subClaims.length === 1;
    const claimCostDistributions = !isSingleSubclaim
      ? claim?.subClaims?.map(({ id }) => ({
          subClaimId: id,
          amountMinorUnits: 0,
        }))
      : [
          {
            subClaimId: claim?.subClaims[0].id,
            amountMinorUnits: totalCostMinorUnits,
          },
        ];

    return {
      claimId: claim?.id,
      total: isSingleSubclaim ? 0 : totalCostMinorUnits,
      claimCostDistributions,
    };
  }, [claim?.id, claim?.subClaims, totalCostMinorUnits]);

  const onChange = useCallback(
    (values) =>
      totalCostMinorUnits - getTotalCostAmount(values?.claimCostDistributions),
    [totalCostMinorUnits],
  );

  return {
    initialValues,
    currencyCode,
    onChange,
  };
}
