import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';

import { CLAIM_STATES } from '../constants';
import { EDIT_CLOSED_CLAIM_PERMISSIONS } from '../overview/content/SubClaims/footer/constants';
import { Entity } from '../overview/types';

import { DOMAINS } from '@/constants/permissions';

export function useCanEditFields(claim: Entity) {
  const hasClaimsEditOrderClaimsClosedPermissions = useCheckPermissions({
    allow: EDIT_CLOSED_CLAIM_PERMISSIONS,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const isClosed = claim?.state === CLAIM_STATES.CLOSED;

  return isClosed ? hasClaimsEditOrderClaimsClosedPermissions.isAllowed : true;
}
