import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Button } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { InputControlled } from '../form/input';

import cn from './style.less';

import { useUpdateClaimStateCommentMutation } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { FALLBACK_TEXT } from '@/constants/common';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
import { useActive } from '@/hooks/useActive';

interface FormModel {
  value?: string;
}

interface Props {
  claimId: string;
  label?: string;
  defaultValue?: string;
  refetchQueries?: string[];
  size?: 'small';
  bordered?: boolean;
}

export function ClaimNextAction({
  claimId,
  label,
  defaultValue,
  refetchQueries,
  size,
  bordered,
}: Props) {
  const { active, setActive, setInactive } = useActive();
  const {
    control,
    setValue,
    getValues,
    resetField,
    formState: { isValid, isDirty },
  } = useForm<FormModel>({
    defaultValues: {},
    mode: 'all',
  });
  const isSmall = size === 'small';
  const { isAllowed } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_UPDATE_CLAIM_STATE_COMMENT,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  useEffect(() => {
    setValue('value', defaultValue);
    resetField('value', { keepDirty: false, defaultValue });
  }, [active, defaultValue, resetField, setValue]);

  const [update, { loading }] = useUpdateClaimStateCommentMutation({
    ...REQUEST_BASE_OPTIONS,
    awaitRefetchQueries: true,
    refetchQueries,
  });

  async function onSubmit() {
    const { value: comment } = getValues();

    await update({
      variables: {
        claimId,
        comment,
      },
    });
    setInactive();
  }

  return (
    <div className={classNames(cn.form, isSmall ? cn.formSmall : null)}>
      {active ? (
        <InputControlled
          formItemClassName={cn.input}
          label={label}
          data-qa-selector="claimNextAction"
          disabled={loading}
          size={size}
          maxLength={100}
          suffix={
            <div
              className={classNames(
                cn.buttons,
                isSmall ? cn.buttonsSmall : cn.buttonsMiddle,
              )}
            >
              <Button
                type="primary"
                size="small"
                disabled={!isValid || !isDirty || !claimId}
                loading={loading}
                icon={<CheckOutlined />}
                onClick={onSubmit}
              />
              <Button
                type="default"
                size="small"
                icon={<CloseOutlined />}
                onClick={setInactive}
              />
            </div>
          }
          controllerProps={{
            name: 'value',
            control,
            rules: {
              maxLength: 100,
            },
          }}
        />
      ) : (
        <section>
          {label ? (
            <div
              className={classNames(
                cn.label,
                isSmall ? cn.heightSmall : cn.heightMiddle,
              )}
            >
              {label}
            </div>
          ) : null}
          <div className={classNames({ [cn.border]: bordered }, cn.preview)}>
            <div className={isSmall ? cn.textSmall : cn.textMiddle}>
              {defaultValue || FALLBACK_TEXT}
            </div>
            {isAllowed ? (
              <div
                className={isSmall ? cn.editButtonSmall : cn.editButtonMiddle}
              >
                <Button type="text" size="small" onClick={setActive}>
                  <EditOutlined />
                </Button>
              </div>
            ) : null}
          </div>
        </section>
      )}
    </div>
  );
}
