import { EditOutlined } from '@ant-design/icons';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Button, Skeleton } from 'antd';
import { isEmpty } from 'lodash/fp';
import { memo } from 'react';
import ReactHelmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { showModal as handleUpdateGroup } from '../../../SubclaimEditGroupModal';
import { Entity } from '../../types';

import cn from './styles.less';

import { DOMAINS, PERMISSIONS } from '@/constants/permissions';

interface HeaderProps {
  claim: Entity;
}

export const Header = memo<HeaderProps>(({ claim }) => {
  const { t } = useTranslation();

  const handleUpdate = () => {
    if (!isEmpty(claim?.costs)) {
      global.alert(t('bo.orderClaims.claimGroupChange.approvedCosts.warning'));
      return;
    }
    handleUpdateGroup(claim);
  };

  const { isAllowed: isClaimGroupEditAllowed } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_CLAIMS_EDIT_ORDER_CLAIMS,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  return (
    <div className={cn.wrapper}>
      <ReactHelmet title="Order Claims" />
      <h3 className={cn.title}>
        {claim?.number ? (
          <>
            <strong data-qa-selector="claim-id">{claim.number}</strong>
            {claim?.claimGroup && (
              <span>
                <span>{' - '}</span>
                <span data-qa-selector="claim-group">
                  {t(claim?.claimGroup?.name)}
                </span>
              </span>
            )}
            {isClaimGroupEditAllowed && (
              <Button
                shape="circle"
                data-qa-selector="claim-group-edit"
                icon={<EditOutlined />}
                onClick={handleUpdate}
                className={cn.editButton}
              />
            )}
          </>
        ) : (
          <Skeleton round paragraph={{ rows: 0 }} />
        )}
      </h3>
    </div>
  );
});
