import { uniq } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { ExtendedSubClaim } from '../../../types';

import { translateOptions } from '@/pages/claims/utils';
import { COST_TYPE_TRANSLATIONS } from '@/pages/claims/constants';

export const useCostTypeOptions = (subClaims: ExtendedSubClaim[]) => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      translateOptions(
        uniq(
          subClaims.flatMap((subClaim) =>
            subClaim.costTypeOptions.map((option) => option.costType),
          ),
        ).map((costType) => ({
          value: costType,
          label: t(COST_TYPE_TRANSLATIONS[costType]),
        })),
        t,
      ),
    [subClaims, t],
  );
};
