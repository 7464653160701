import { makeVar, useReactiveVar } from '@apollo/client';
import { Button, Card, Modal } from 'antd';
import { SyntheticEvent, memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Entity } from '../overview/types';
import { createCommentsPayload } from '../utils';

import {
  SubClaimCreateForm,
  SubclaimCreateFormValues,
} from './SubClaimCreateForm';
import { useCreateSubclaim } from './useCreateSubclaim';

import {
  RetailClaimGroupsOptionsQuery,
  RetailSubClaimCreateProjectionInput,
} from '@/apollo/gql-types';

const modalVars = makeVar<{ visible?: boolean; claim?: Entity }>({});

export const hideModal = () => modalVars({});
export const showModal = (claim: Entity) => modalVars({ claim, visible: true });

interface Props {
  claimGroups: RetailClaimGroupsOptionsQuery;
  retailCountry: string;
  orderId: string;
}

interface SubClaimPayload extends RetailSubClaimCreateProjectionInput {
  newDocuments: File[];
}

export const SubClaimCreateModal = memo<Props>(
  ({ claimGroups, retailCountry, orderId }) => {
    const { visible, claim } = useReactiveVar(modalVars);
    const { t } = useTranslation();
    const createSubClaim = useCreateSubclaim({
      orderId: claim?.order?.id,
      claimId: claim?.id,
      retailCountry,
    });

    const form = useForm<SubclaimCreateFormValues>({
      defaultValues: {},
      mode: 'all',
    });
    const {
      control,
      formState: { isSubmitting, errors, isDirty, isValid },
      handleSubmit,
      reset,
    } = form;

    useEffect(
      () =>
        visible &&
        reset({
          claimGroupId: claim?.claimGroupId,
        }),
      [visible, reset, claim?.claimGroupId],
    );

    const disabled = !isDirty || !isValid;

    async function onHandleSubmit(event: SyntheticEvent) {
      if (disabled) {
        return;
      }

      await handleSubmit(
        ({
          areaId,
          carPartId,
          comment,
          damageId,
          liablePartyType,
          newDocuments,
          complaintId,
          workType,
          timeDependencyType,
          warrantyCoverageType,
        }) => {
          const subClaimPayload: SubClaimPayload = {
            complaintId,
            areaId,
            carPartId,
            damageId,
            liablePartyType,
            newDocuments,
            workType,
            timeDependencyType,
            warrantyCoverageType,
          };
          const subClaimComments = createCommentsPayload({
            visibleForCustomer: comment,
          });
          if (subClaimComments.length > 0) {
            subClaimPayload.comments = subClaimComments;
          }

          createSubClaim(subClaimPayload);
        },
      )(event);

      hideModal();
    }

    return (
      <Modal
        centered
        visible={visible}
        width={1000}
        onCancel={hideModal}
        data-qa-selector="subclaim-create-modal"
        title={<span>{`${t('bo.orderClaims.form.requestSubclaim')}: `}</span>}
        footer={
          <>
            <Button type="default" onClick={hideModal}>
              <span data-qa-selector="cancel-subclaim-creation">
                {t('bo.orderClaims.form.cancelBtnText')}
              </span>
            </Button>
            <Button
              type="primary"
              loading={isSubmitting}
              disabled={!isDirty || !isValid}
              onClick={onHandleSubmit}
            >
              <span data-qa-selector="create-subclaim">
                {t('bo.orderClaims.form.saveBtnText')}
              </span>
            </Button>
          </>
        }
      >
        <Card>
          <FormProvider {...form}>
            <form onSubmit={onHandleSubmit}>
              <SubClaimCreateForm
                claim={claim}
                control={control}
                claimGroups={claimGroups}
                errors={errors}
                orderId={orderId}
              />
            </form>
          </FormProvider>
        </Card>
      </Modal>
    );
  },
);
