import { FormItem, OptionModel, SelectControlled } from '@retail/backoffice-ui';
import { Alert, Col, Divider, Radio, RadioChangeEvent, Row } from 'antd';
import { find } from 'lodash/fp';
import { useEffect, useMemo } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCanEditFields } from '../hooks/useCanEditFields';
import { useClearFieldsValue } from '../hooks/useClearFieldsValue';
import { useIsSubClaimDisabled } from '../hooks/useIsSubClaimDisabled';
import { Entity, ExtendedSubClaim } from '../overview/types';
import { MechanicalClaimQuestionsFormGroup } from '../claim-create-modal/MechanicalClaimQuestionsFormGroup';
import { isTechnicalMechanicalClaim } from '../utils';

import selectors from './selectors';

import { FIELD_LABEL_COL } from '@/constants/common';
import { useRetailClaimV2GroupsOptionsQuery } from '@/apollo/gql-types';
import { useDebouncedValue } from '@/hooks/useDebouncedValue';

export const WARRANTY_COVERAGE_TYPE_NAME = 'warrantyCoverageType';
export const AREA_ID_NAME = 'areaId';
export const DAMAGE_ID_NAME = 'damageId';
export const CAR_PART_ID_NAME = 'carPartId';
export const LIABLE_PARTY_TYPE_NAME = 'liablePartyType';

interface Props {
  claim?: Entity;
  control: Control;
  orderId: string;
  claimGroupId: string;
  claimGroupName: string;
  allowClearAreaPartDamage?: boolean;
  subClaim?: ExtendedSubClaim;
}

export function SubClaimV2Form({
  claim,
  subClaim,
  orderId,
  control,
  claimGroupId,
  claimGroupName,
  allowClearAreaPartDamage,
}: Props) {
  const { setValue } = useFormContext();
  const [areaId, carPartId, damageId, liablePartyType, warrantyType] = useWatch(
    {
      control,
      name: [
        AREA_ID_NAME,
        CAR_PART_ID_NAME,
        DAMAGE_ID_NAME,
        LIABLE_PARTY_TYPE_NAME,
        WARRANTY_COVERAGE_TYPE_NAME,
      ],
    },
  );
  const clearFieldsValue = useClearFieldsValue();

  const { t } = useTranslation();
  const workType = useWatch({ control, name: 'workType' });
  const mileage =
    useWatch({
      control,
      name: 'carDetails.mileage',
    }) || claim?.carDetails?.mileage;
  const timeDependencyType = useWatch({
    control,
    name: 'timeDependencyType',
  });

  const isTechnicalMechanical = isTechnicalMechanicalClaim(claimGroupName);

  const debouncedMileage = useDebouncedValue(mileage, 500);
  const claimGroups = useRetailClaimV2GroupsOptionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      request: {
        claimId: claim?.id,
        claimGroupId,
        mileage: debouncedMileage,
        orderId,
        timeDependencyType,
        workType,
      },
    },
    skip: isTechnicalMechanical
      ? !workType ||
        !timeDependencyType ||
        !debouncedMileage ||
        workType === 'NOT_ACCOUNTED' ||
        timeDependencyType === 'NOT_ACCOUNTED'
      : false,
    onCompleted: (res) => {
      const shouldResetFields = !res?.claimGroups
        ?.filter((group) => group.id === claimGroupId)
        .some((group) => group?.areas?.some((area) => area.id === areaId));

      if (shouldResetFields) {
        clearFieldsValue(
          AREA_ID_NAME,
          CAR_PART_ID_NAME,
          DAMAGE_ID_NAME,
          LIABLE_PARTY_TYPE_NAME,
        );
      }
    },
  });

  const selectedClaimGroups = useMemo(
    () =>
      claimGroups?.data?.claimGroups?.filter(
        (group) =>
          group.id === claimGroupId &&
          group.warrantyCoverageType === warrantyType,
      ),
    [claimGroupId, claimGroups?.data?.claimGroups, warrantyType],
  );

  const { labels, areaOptions, damageOptions, ...restOptions } = useMemo(
    () =>
      selectors({
        t,
        areaId,
        damageId,
        carPartId,
        claimGroups: {
          __typename: 'Query',
          claimGroups: selectedClaimGroups,
        },
        claimGroupId,
      }),
    [t, areaId, damageId, carPartId, selectedClaimGroups, claimGroupId],
  );

  const damageOptionsContainsSingleNaValue =
    damageOptions &&
    damageOptions.length === 1 &&
    damageOptions[0].label?.toString()?.includes('N/A');
  useEffect(() => {
    if (damageOptionsContainsSingleNaValue) {
      setValue(DAMAGE_ID_NAME, damageOptions[0].value);
    }
  }, [damageOptions, setValue, damageOptionsContainsSingleNaValue]);

  const carPartOptions = areaId
    ? restOptions.carPartOptions
    : restOptions.carPartAllOptions;
  const carPartOptionsContainsSingleNaValue =
    carPartOptions &&
    carPartOptions.length === 1 &&
    carPartOptions[0].label?.toString()?.includes('N/A');
  useEffect(() => {
    if (carPartOptionsContainsSingleNaValue) {
      setValue(CAR_PART_ID_NAME, carPartOptions[0].value);
    }
  }, [setValue, carPartOptionsContainsSingleNaValue, carPartOptions]);

  const liablePartyOptions = useMemo(
    () =>
      selectedClaimGroups?.[0]?.areas
        ?.find((area) => area.id === areaId)
        ?.liableParties?.map((party) => ({
          label: t(`bo.orderClaims.submitReason.liableParty.${party}`),
          value: party,
        })) ?? [],
    [areaId, selectedClaimGroups, t],
  );

  const [areaLabel, partLabel, damageLabel] = labels;
  const canEditFields = useCanEditFields(claim);

  const isSubClaimDisabled = useIsSubClaimDisabled(claim);
  const isEmptyAreaOptions = !areaOptions.length;
  const isEmptyCarPartOptions = !carPartOptions.length;
  const isEmptyDamageOptions = !damageOptions.length;

  const liablePartyOptionsWithMissingParty = useMemo(() => {
    if (
      liablePartyType &&
      !find({ value: liablePartyType }, liablePartyOptions)
    ) {
      return [
        ...liablePartyOptions,
        {
          value: liablePartyType,
          label: t(
            `bo.orderClaims.submitReason.liableParty.${liablePartyType}`,
          ),
        },
      ];
    }
    return liablePartyOptions;
  }, [liablePartyOptions, liablePartyType, t]);

  const warrantyTypeOptions: OptionModel[] = useMemo(
    () =>
      (claimGroups?.data?.claimGroups ?? [])
        ?.filter((group) => group.id === claimGroupId)
        .map((claimGroup) => ({
          value: claimGroup.warrantyCoverageType,
          label: t(
            `bo.orderClaims.warrantyCoverage.${claimGroup.warrantyCoverageType}`,
          ),
        })),
    [claimGroupId, claimGroups?.data?.claimGroups, t],
  );

  useEffect(() => {
    const shouldUpdateWarranty =
      !warrantyType ||
      warrantyTypeOptions.every((option) => option.value !== warrantyType);
    if (shouldUpdateWarranty && warrantyTypeOptions.length === 1) {
      setValue(WARRANTY_COVERAGE_TYPE_NAME, warrantyTypeOptions?.[0]?.value);
    }
  }, [warrantyType, setValue, warrantyTypeOptions]);

  const isEmptyWarrantyTypeOptions = !warrantyTypeOptions.length;

  const isSubClaimWithExistingCost = subClaim?.distributedCosts?.length > 0;

  const warrantyTypeControllerProps: UseControllerProps<FieldValues> = {
    name: WARRANTY_COVERAGE_TYPE_NAME,
    control,
  };

  const { field: warrantyTypeField } = useController(
    warrantyTypeControllerProps,
  );

  const warrantyTypeDisabled =
    isSubClaimDisabled || isEmptyWarrantyTypeOptions || !canEditFields;

  const handleWarrantyChange = (event: RadioChangeEvent) => {
    warrantyTypeField.onChange(event);
    clearFieldsValue(AREA_ID_NAME, CAR_PART_ID_NAME, DAMAGE_ID_NAME);
  };

  return (
    <>
      {isTechnicalMechanical ? (
        <>
          {isSubClaimWithExistingCost ? (
            <Alert
              type="warning"
              style={{ marginBottom: 12 }}
              data-qa-selector="subclaimWithCostWarningMessage"
              message={t(
                'bo.orderClaims.technicalMechanicalSubclaim.costExistsWarningMessage',
              )}
            />
          ) : null}
          <MechanicalClaimQuestionsFormGroup
            control={control}
            disabled={isSubClaimWithExistingCost}
          />
          <Divider />
        </>
      ) : null}
      <Row gutter={16}>
        <Col span={24} md={12} data-qa-selector={'warrantyType'}>
          <FormItem
            label={t('bo.orderClaims.warrantyType')}
            required
            disabled={warrantyTypeDisabled}
            controllerProps={warrantyTypeControllerProps}
          >
            <Radio.Group {...warrantyTypeField} onChange={handleWarrantyChange}>
              {warrantyTypeOptions.map((option) => (
                <Radio
                  key={option.value}
                  disabled={warrantyTypeDisabled}
                  value={option.value}
                >
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} md={8} data-qa-selector={AREA_ID_NAME}>
          <SelectControlled
            labelCol={FIELD_LABEL_COL}
            showSearch
            label={t(areaLabel)}
            onChange={() => clearFieldsValue(CAR_PART_ID_NAME, DAMAGE_ID_NAME)}
            placeholder={t('bo.orderClaims.form.selectPlaceholder')}
            options={areaOptions}
            allowClear={allowClearAreaPartDamage}
            disabled={
              isSubClaimDisabled || isEmptyAreaOptions || !canEditFields
            }
            controllerProps={{
              name: AREA_ID_NAME,
              control,
            }}
          />
        </Col>
        <Col span={24} md={8} data-qa-selector={CAR_PART_ID_NAME}>
          <SelectControlled
            labelCol={FIELD_LABEL_COL}
            showSearch
            label={t(partLabel)}
            onChange={(partId) => {
              if (!areaId) {
                const areaIdToSelect = restOptions.carPartAllOptions.find(
                  (it) => it.value === partId,
                )?.areaId;
                if (areaIdToSelect) {
                  setValue(AREA_ID_NAME, areaIdToSelect, {
                    shouldValidate: true,
                  });
                }
              }
              clearFieldsValue(DAMAGE_ID_NAME);
            }}
            placeholder={t('bo.orderClaims.form.selectPlaceholder')}
            options={carPartOptions}
            allowClear={allowClearAreaPartDamage}
            disabled={
              carPartOptionsContainsSingleNaValue ||
              isSubClaimDisabled ||
              isEmptyCarPartOptions ||
              !canEditFields
            }
            controllerProps={{
              name: CAR_PART_ID_NAME,
              control,
            }}
          />
        </Col>
        <Col span={24} md={8} data-qa-selector={DAMAGE_ID_NAME}>
          <SelectControlled
            labelCol={FIELD_LABEL_COL}
            showSearch
            label={t(damageLabel)}
            placeholder={t('bo.orderClaims.form.selectPlaceholder')}
            options={damageOptions}
            allowClear={allowClearAreaPartDamage}
            disabled={
              damageOptionsContainsSingleNaValue ||
              isSubClaimDisabled ||
              isEmptyDamageOptions ||
              !canEditFields
            }
            controllerProps={{
              name: DAMAGE_ID_NAME,
              control,
            }}
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col
          span={24}
          md={{ span: 12 }}
          data-qa-selector={LIABLE_PARTY_TYPE_NAME}
        >
          <SelectControlled
            labelCol={FIELD_LABEL_COL}
            showSearch
            label={t('bo.orderClaims.liableParty')}
            placeholder={t('bo.orderClaims.form.selectPlaceholder')}
            options={liablePartyOptionsWithMissingParty}
            disabled={!liablePartyOptionsWithMissingParty.length}
            controllerProps={{
              name: LIABLE_PARTY_TYPE_NAME,
              control,
            }}
          />
        </Col>
      </Row>
    </>
  );
}
