import { EditOutlined, MessageOutlined } from '@ant-design/icons';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Button } from 'antd';

import { CLAIM_SOURCE_TYPES, CLAIM_STATES } from '../../../constants';
import { showModal as showSubclaimCommentAndDocumentsModal } from '../../../SubclaimCreateCommentAndDocumentsModal';
import { showModal as showSubclaimModal } from '../../../SubclaimEditModal';
import { ExtendedSubClaim, Entity } from '../../types';

import { SubclaimDeleteButton } from './delete-button';
import cn from './styles.less';

import { DOMAINS, PERMISSIONS } from '@/constants/permissions';

interface Props {
  index: number;
  subclaim: ExtendedSubClaim;
  claim: Entity;
}

export function Actions({ claim, subclaim, index }: Props) {
  const { isAllowed: hasDeleteSubClaimPermission } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_ORDER_CLAIMS_DELETE_SUB_CLAIM,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  return (
    <div className={cn.actions}>
      <Button
        type="primary"
        data-qa-selector="subclaim-edit"
        icon={<EditOutlined />}
        onClick={() => showSubclaimModal(claim, subclaim)}
      />
      <SubclaimDeleteButton
        subClaimId={subclaim.id}
        subclaimNumber={subclaim.number}
        disabled={
          index === 0 ||
          claim?.state === CLAIM_STATES.CLOSED ||
          subclaim?.root ||
          !hasDeleteSubClaimPermission ||
          subclaim?.sourceType === CLAIM_SOURCE_TYPES.AUTO_HERO
        }
      />
      <Button
        type="primary"
        data-qa-selector="subclaim-comment"
        icon={<MessageOutlined />}
        onClick={() => showSubclaimCommentAndDocumentsModal(claim, subclaim)}
      />
    </div>
  );
}
