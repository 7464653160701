import { find, flow, get, head, isEmpty } from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

import { COST_TYPE } from '../../../constants';
import {
  claimGroupsSelector,
  claimTotalCostSelector,
  claimsSelector,
  orderClaimsCostsSelector,
} from '../../../selectors';

import { RetailClaimCostProjection } from '@/apollo/gql-types';

export const isWorkshopCostsSelector = createSelector(
  orderClaimsCostsSelector,
  find({ costType: COST_TYPE.WORKSHOP }),
);

export const orderClaimStatusSelector = createSelector(
  claimsSelector,
  flow(head, get('state')),
);

export const isSubClaimsDamageInfoCompleteSelector = createSelector(
  claimsSelector,
  claimGroupsSelector,
  (claims, claimGroups) => {
    const claim = head(claims);
    return claim?.subClaims?.every(
      ({ areaId, carPartId, damageId, groupData }) => {
        const claimGroupId = get('claimGroupId', claim);

        if (claim?.flowVersion === 'V2') {
          const selectedArea = groupData?.area;
          const selectedPart = selectedArea?.carPart;
          const selectedDamage = selectedPart?.damage;

          return Boolean(
            selectedArea?.id && selectedPart?.id && selectedDamage?.id,
          );
        } else {
          const areas = flow(
            find({ id: claimGroupId }),
            get('areas'),
          )(claimGroups);

          if (!areaId && !isEmpty(areas)) {
            return false;
          }
          const carParts = flow(find({ id: areaId }), get('carParts'))(areas);

          if (!carPartId && !isEmpty(carParts)) {
            return false;
          }
          const damages = flow(
            find({ id: carPartId }),
            get('damages'),
          )(carParts);

          return !(!damageId && !isEmpty(damages));
        }
      },
    );
  },
);

export default createStructuredSelector<
  unknown,
  {
    costs: RetailClaimCostProjection[];
    claimTotalCost: number;
    isWorkshopCosts: unknown;
    orderClaimStatus: string;
    isSubClaimsDamageInfoComplete: unknown;
  }
>({
  costs: orderClaimsCostsSelector,
  claimTotalCost: claimTotalCostSelector,
  isWorkshopCosts: isWorkshopCostsSelector,
  orderClaimStatus: orderClaimStatusSelector,
  isSubClaimsDamageInfoComplete: isSubClaimsDamageInfoCompleteSelector,
});
