import { FormItem } from '@retail/backoffice-ui';
import {
  CurrencyCodes,
  getFormatInputFromMinorUnits,
  getParseInputToMinorUnits,
} from '@retail/currency';
import { InputNumber, InputNumberProps, InputProps } from 'antd';
import { CSSProperties, ReactNode, memo } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

type PriceInputControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  step?: number;
  min?: number;
  required?: boolean;
  disabled?: boolean;
  currencyCode?: CurrencyCodes;
  wrapperStyles?: CSSProperties;
  errorMessage?: string;
  parse?: (value: unknown) => string | number;
  format?: (value: unknown) => string | number;
} & (InputProps & InputNumberProps);

const PriceInputControlledComponent = <T extends FieldValues>({
  label,
  controllerProps,
  min = 0,
  step = 0.01,
  currencyCode,
  errorMessage,
  required,
  disabled,
  format,
  parse,
  onChange,
  onBlur,
  wrapperStyles,
  ...restInputProps
}: PriceInputControlledProps<T>) => {
  const { i18n } = useTranslation();
  const { field } = useController(controllerProps);
  const formatValue = format || getFormatInputFromMinorUnits(currencyCode);
  const parseValue = parse || getParseInputToMinorUnits(currencyCode);

  return (
    <FormItem
      label={label}
      required={required}
      disabled={disabled}
      errorMessage={errorMessage}
      style={wrapperStyles}
      controllerProps={controllerProps}
    >
      <div className={cn.inputWrapper}>
        <InputNumber
          min={min}
          step={step}
          disabled={disabled}
          {...field}
          lang={i18n.language}
          value={formatValue(field.value) as string}
          onChange={(value) => {
            const parsedValue = parseValue(value);
            onChange?.(parsedValue);
            field.onChange(parsedValue);
          }}
          onBlur={(e) => {
            onBlur?.(e);
            field.onBlur();
          }}
          {...restInputProps}
        />
      </div>
    </FormItem>
  );
};

export const PriceInputControlled = memo(
  PriceInputControlledComponent,
) as typeof PriceInputControlledComponent;
