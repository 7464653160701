import { Form, InputNumber, Space } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const maxMileage = 999999;
const minMileage = 0;

type MileageInputProps = {
  loading?: boolean;
  required?: boolean;
  suffix?: ReactNode;
  labelCol?: number;
  addonAfter?: ReactNode;
  value: number;
  onChange: (value: number) => void;
  readonly?: boolean;
  disabled?: boolean;
  status?: 'error';
  errorMessage?: string;
};

export const MileageInput = ({
  suffix,
  labelCol = 3,
  addonAfter,
  readonly,
  value,
  onChange,
  required,
  loading,
  disabled,
  status,
  errorMessage,
}: MileageInputProps) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      label={t('bo.orderClaims.mileage')}
      labelCol={{ span: labelCol }}
      required={required}
      validateStatus={status}
      help={errorMessage}
    >
      <Space size="small">
        <InputNumber<number>
          min={minMileage}
          status={status}
          required
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onPaste={(e) => {
            e.preventDefault();
            const data = e.clipboardData.getData('text/plain');
            const digitsOnly = data.replace(/\D/gm, '');
            const inputElement = e.target;
            if (inputElement instanceof HTMLInputElement) {
              inputElement.value = digitsOnly;
            }
          }}
          data-qa-selector="mileage-field"
          max={maxMileage}
          disabled={loading || disabled}
          value={value}
          onChange={onChange}
          type="number"
          readOnly={readonly}
          addonBefore="km"
          addonAfter={addonAfter}
        />
        {suffix}
      </Space>
    </Form.Item>
  );
};
