import { formatCurrency } from '@retail/backoffice-ui';
import { CurrencyCodes } from '@retail/currency';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { ExtendedSubClaim } from '../../../types';
import { Price } from '../price';

import cn from './styles.less';

const NoDataMessage = () => {
  const { t } = useTranslation();
  return (
    <div className={cn.subclaimCostsNoData}>
      <i>{t('bo.orderClaims.processing.popUp.subclaimCostsNoData')}</i>
    </div>
  );
};

interface CostColumnProps {
  claimFlowVersion: string;
  subClaim: ExtendedSubClaim;
  claimApprovedCostIds: string[];
}

const { Text } = Typography;

export const CostColumn = ({
  claimFlowVersion,
  subClaim,
  claimApprovedCostIds,
}: CostColumnProps) => {
  const { t } = useTranslation();

  const currencyCode = subClaim.costCurrencyCode as CurrencyCodes;

  if (claimFlowVersion === 'V2') {
    const approvedCosts = subClaim.distributedCosts.filter((it) =>
      claimApprovedCostIds.includes(it.costId),
    );

    if (approvedCosts.length) {
      const [estimatedMinorUnits, approvedMinorUnits] = approvedCosts.reduce(
        ([estimated, approved], cost) => [
          estimated + cost.estimateAmountMinorUnits,
          approved + cost.amountMinorUnits,
        ],
        [0, 0],
      );

      return (
        <div className={cn.distributedCosts}>
          <Text>
            {t('bo.orderClaims.processing.table.subclaimCosts.estimatedCost')}:
          </Text>
          <Text>
            {formatCurrency({
              amountMinorUnits: estimatedMinorUnits,
              currencyCode,
            })}
          </Text>
          <Text strong>
            {t('bo.orderClaims.processing.table.subclaimCosts.approvedCost')}:
          </Text>
          <Text strong>
            {formatCurrency({
              currencyCode,
              amountMinorUnits: approvedMinorUnits,
            })}
          </Text>
        </div>
      );
    }

    return <NoDataMessage />;
  }

  return Number.isFinite(subClaim.costMinorUnits) ? (
    <Price
      price={{
        currencyCode,
        amountMinorUnits: subClaim.costMinorUnits,
      }}
    />
  ) : (
    <NoDataMessage />
  );
};
