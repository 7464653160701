import { Control, FieldPath } from 'react-hook-form';
import { CurrencyCodes, getCurrencySymbolByCode } from '@retail/currency';

import { CostFormV2Values } from './types';

import { PriceInputControlled } from '@/components/form/price';
import {
  allowOnlyNumbersAndPunctuation,
  getPercentageOfValue,
} from '@/pages/claims/utils';

type CostValues = CostFormV2Values['costs'][number];

type CostEstimateControlProps = {
  onCompensationAmountMinorUnitsChange: (
    value: CostValues['compensationAmountMinorUnits'],
  ) => void;
  costValues: CostValues;
  disabled: boolean;
  required: boolean;
  name: FieldPath<CostFormV2Values>;
  control: Control<CostFormV2Values>;
  currencyCode: CurrencyCodes;
};

export const CostEstimateControl = ({
  disabled,
  control,
  name,
  currencyCode,
  costValues,
  required,
  onCompensationAmountMinorUnitsChange,
}: CostEstimateControlProps) => (
  <PriceInputControlled
    type="number"
    data-qa-selector="cost-estimate-field"
    size="large"
    required={required}
    disabled={disabled}
    onKeyPress={allowOnlyNumbersAndPunctuation}
    wrapperStyles={{ marginBottom: 0 }}
    controllerProps={{
      control,
      name,
    }}
    addonBefore={
      <span style={{ fontFamily: 'monospace' }}>
        {getCurrencySymbolByCode(currencyCode)}
      </span>
    }
    onBlur={() => {
      const costEstimateMinorUnits = costValues.costEstimateAmountMinorUnits;

      const percentage = costValues.compensationPercentage;

      if (percentage) {
        const compensationAmount = getPercentageOfValue(
          costEstimateMinorUnits,
          percentage,
          0,
        );
        onCompensationAmountMinorUnitsChange(compensationAmount);
      }
    }}
  />
);
