import { Entity } from '../overview/types';

import { useFeatures } from '@/providers/FeaturesProvider';
import { FEATURE } from '@/constants/features';

export const useIsClaimV2FlowEnabled = (
  claim?: Pick<Entity, 'flowVersion'>,
) => {
  const features = useFeatures();
  const isClaimV2FeatureEnabled = features[FEATURE.BO_FE_CLAIMS_FLOW_V2];

  if (!claim) {
    return isClaimV2FeatureEnabled;
  }

  return claim.flowVersion === 'V2';
};
