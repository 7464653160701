import { Card } from 'antd';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CLAIM_STATES } from '../../../constants';
import { isAnyActiveCost } from '../../helpers';
import { Entity, ExtendedSubClaim } from '../../types';

import SubClaim from './SubClaim';
import { Footer } from './footer';
import { useCostCalculations } from './hooks/useCostCalculations';

interface SubclaimsProps {
  claim: Entity;
  loading: boolean;
}

export const SubClaims = memo<SubclaimsProps>(({ claim, loading }) => {
  const { t } = useTranslation();
  const { claimTotalCost } = useCostCalculations(claim);

  const isAnyActiveCostResult = useMemo(
    () => isAnyActiveCost(claim?.costs),
    [claim?.costs],
  );
  const isClosed = claim?.state === CLAIM_STATES.CLOSED;

  const isAddSubClaimCostDisabled =
    isClosed ||
    isAnyActiveCostResult ||
    claimTotalCost.amountMinorUnits === 0 ||
    loading;

  const subClaims = claim?.subClaims || [];
  const complaints = claim?.complaints || [];

  return (
    <>
      <Card
        bordered
        loading={loading}
        headStyle={{ background: '#f5f5f5', fontWeight: 500 }}
        bodyStyle={{ padding: 0 }}
        title={t('bo.orderClaims.processing.table.subclaims.title')}
      >
        {subClaims.map((subClaim: ExtendedSubClaim, index: number) => {
          const complaint = complaints.find(
            ({ id }) => id === subClaim?.complaintId,
          );
          return (
            <SubClaim
              claim={claim}
              complaint={complaint}
              subClaim={subClaim}
              key={subClaim?.id}
              index={index}
            />
          );
        })}
      </Card>

      <Footer
        claim={claim}
        isAddSubClaimCostDisabled={isAddSubClaimCostDisabled}
      />
    </>
  );
});
