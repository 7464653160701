import { makeVar } from '@apollo/client';
import { CurrencyCodes } from '@retail/currency';

import { CostFormV2Values } from './types';

import { ClaimCostType } from '@/apollo/gql-types';

export type CostModalV2Values = {
  id?: string;
  cost: null | {
    id?: string;
    orderItemId?: string;
    costType?: ClaimCostType;
    warrantyType?: string;
    costs: CostFormV2Values['costs'];
  };
  approvedCostMinorUnits?: number;
  currencyCode?: CurrencyCodes;
};

export const manageCostModalV2 = makeVar<{
  visible: boolean;
  mode: 'view' | 'edit' | 'create';
  data: CostModalV2Values | null;
}>({ visible: false, data: null, mode: 'view' });

export const hideModalV2 = () =>
  manageCostModalV2({ visible: false, data: null, mode: 'view' });
export const showModalV2 = (
  data: CostModalV2Values,
  mode: 'view' | 'edit' | 'create',
) => manageCostModalV2({ visible: true, data, mode });
