import { Row } from 'antd';
import { Control } from 'react-hook-form';

import { ClaimGroupControl } from '../claim-group-control';
import { Entity } from '../overview/types';
import { SubclaimCreateCommentAndDocumentsForm } from '../SubclaimCreateCommentAndDocumentsForm';
import { SubclaimForm } from '../SubclaimForm';
import { SubClaimV2Form } from '../SubclaimForm/SubClaimV2Form';
import { useIsClaimV2FlowEnabled } from '../hooks/useIsClaimV2FlowEnabled';

import {
  RetailClaimGroupsOptionsQuery,
  RetailSubClaimCreateProjectionInput,
} from '@/apollo/gql-types';

export interface SubclaimCreateFormValues
  extends Omit<RetailSubClaimCreateProjectionInput, 'comments' | 'comment'> {
  newDocuments?: File[];
  claimGroupId?: string;
  comment?: string;
}

interface Props {
  claim: Entity;
  control: Control;
  claimGroups: RetailClaimGroupsOptionsQuery;
  errors: unknown;
  orderId: string;
}

export function SubClaimCreateForm({
  claim,
  control,
  claimGroups,
  errors,
  orderId,
}: Props) {
  const isClaimV2FlowEnabled = useIsClaimV2FlowEnabled(claim);

  return (
    <>
      <Row gutter={20}>
        <ClaimGroupControl
          claimGroups={claimGroups}
          control={control}
          disabled
          isGroupSelected
        />
      </Row>
      {isClaimV2FlowEnabled ? (
        <SubClaimV2Form
          allowClearAreaPartDamage
          claim={claim}
          orderId={orderId}
          control={control}
          claimGroupName={claim?.claimGroup?.name}
          claimGroupId={claim?.claimGroupId}
        />
      ) : (
        <SubclaimForm
          allowClearAreaPartDamage
          claim={claim}
          control={control}
          claimGroupId={claim?.claimGroupId}
          claimGroups={claimGroups}
        />
      )}
      <SubclaimCreateCommentAndDocumentsForm
        claim={claim}
        control={control}
        errors={errors}
      />
    </>
  );
}
