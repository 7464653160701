import { useReactiveVar } from '@apollo/client';
import { Modal, notification } from 'antd';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from '../cost-modal/styles.less';
import { ExtendedSubClaim } from '../../../types';

import { hideModalV2, manageCostModalV2 } from './manage-cost-modal-v2';
import { CostForm } from './cost-form';
import { CostFormV2Values } from './types';
import { useCostTypeOptions } from './useCostTypeOptions';

import {
  SearchRetailClaimsDocument,
  useDistributeRetailClaimV2CostMutation,
  useUpdateRetailClaimCostDistributionMutation,
} from '@/apollo/gql-types';

export const CostModalV2 = memo(
  ({ subClaims }: { subClaims: ExtendedSubClaim[] }) => {
    const { visible, data, mode } = useReactiveVar(manageCostModalV2);
    const { t } = useTranslation();
    const { id, cost, currencyCode, approvedCostMinorUnits } = data ?? {};

    const initialValues: CostFormV2Values = useMemo(
      () => ({
        costs: [] as CostFormV2Values['costs'],
        costType: cost?.costType || null,
        orderItemId: cost?.orderItemId,
      }),
      [cost],
    );

    const { handleSubmit, control, reset, watch, setValue } = useForm<
      typeof initialValues
    >({
      defaultValues: initialValues,
      mode: 'all',
    });
    const selectedCostType = watch('costType');
    const costId = cost?.id;
    const subClaimsFilteredByCostType = useMemo(
      () =>
        selectedCostType
          ? subClaims.filter((subClaim) =>
              subClaim.costTypeOptions?.some(
                (option) => option.costType === selectedCostType,
              ),
            )
          : [],
      [selectedCostType, subClaims],
    );
    const subClaimsForDistribution =
      mode === 'create'
        ? subClaimsFilteredByCostType
        : subClaimsFilteredByCostType.filter((subClaim) =>
            subClaim.distributedCosts.some(
              (distribution) => distribution.costId === cost?.id,
            ),
          );
    const subClaimsCostDistribution: CostFormV2Values['costs'] = useMemo(
      () =>
        mode === 'create'
          ? subClaimsForDistribution.map((subClaim) => ({
              subClaimId: subClaim.id,
              costEstimateAmountMinorUnits: 0,
              compensationAmountMinorUnits: 0,
              compensationPercentage: 0,
              liableParty: subClaim.liablePartyType,
            }))
          : subClaimsForDistribution
              .map((subClaim) => ({
                ...subClaim,
                distributedCost: subClaim.distributedCosts.find(
                  (cost) => cost.costId === costId,
                ),
              }))
              .filter((subClaim) => subClaim?.distributedCost)
              .map((subClaim) => ({
                subClaimId: subClaim.id,
                costEstimateAmountMinorUnits:
                  subClaim?.distributedCost.estimateAmountMinorUnits,
                compensationAmountMinorUnits:
                  subClaim?.distributedCost?.amountMinorUnits,
                compensationPercentage: subClaim.distributedCost
                  ?.estimateAmountMinorUnits
                  ? +(
                      (subClaim.distributedCost?.amountMinorUnits /
                        subClaim.distributedCost?.estimateAmountMinorUnits) *
                      100
                    ).toFixed(2)
                  : 0,
                liableParty: subClaim.liablePartyType,
              })),
      [costId, mode, subClaimsForDistribution],
    );

    useEffect(() => {
      setValue('costs', subClaimsCostDistribution);
    }, [setValue, subClaimsCostDistribution]);

    useEffect(() => reset(initialValues), [initialValues, reset]);

    const [
      distributeRetailClaimCost,
      { loading: distributeRetailClaimCostLoading },
    ] = useDistributeRetailClaimV2CostMutation({
      refetchQueries: [SearchRetailClaimsDocument],
      onCompleted: () => {
        hideModalV2();
        notification.success({
          message: 'Saved successfully',
        });
      },
    });

    const [
      updateClaimCostDistribution,
      { loading: updateClaimCostDistributionLoading },
    ] = useUpdateRetailClaimCostDistributionMutation({
      refetchQueries: [SearchRetailClaimsDocument],
      onCompleted: () => {
        hideModalV2();
        notification.success({
          message: 'Saved successfully',
        });
      },
    });

    const onSubmit = useCallback(
      async (costValues: CostFormV2Values) => {
        const costsDistribution =
          mode === 'create'
            ? costValues.costs.filter(
                (cost) =>
                  cost.liableParty ||
                  cost.compensationAmountMinorUnits ||
                  cost.costEstimateAmountMinorUnits,
              )
            : costValues.costs;
        const totalCompensation = costsDistribution.reduce(
          (prev, curr) => prev + curr.compensationAmountMinorUnits,
          0,
        );
        const totalCostEstimate = costsDistribution.reduce(
          (prev, curr) => prev + curr.costEstimateAmountMinorUnits,
          0,
        );

        if (mode === 'edit') {
          await updateClaimCostDistribution({
            variables: {
              claimCostDistribution: {
                claimCostId: cost.id,
                costType: cost.costType,
                orderItemId: costValues.orderItemId,
                distributedItems: costsDistribution.map((cost) => ({
                  amountMinorUnits: cost.compensationAmountMinorUnits,
                  costEstimateAmountMinorUnits:
                    cost.costEstimateAmountMinorUnits,
                  subClaimId: cost.subClaimId,
                  subClaimLiableParty: cost.liableParty,
                })),
              },
            },
          });
        }

        if (mode === 'create') {
          await distributeRetailClaimCost({
            variables: {
              claimCostDistribution: {
                claimId: id,
                cost: {
                  cost: totalCompensation,
                  costEstimate: totalCostEstimate,
                  costType: costValues.costType,
                  orderItemId: costValues.orderItemId,
                },
                distributedSubClaims: costsDistribution.map((cost) => ({
                  amountMinorUnits: cost.compensationAmountMinorUnits,
                  costEstimateAmountMinorUnits:
                    cost.costEstimateAmountMinorUnits,
                  subClaimId: cost.subClaimId,
                  subClaimLiableParty: cost.liableParty,
                })),
              },
            },
          });
        }
      },
      [
        cost?.costType,
        cost?.id,
        distributeRetailClaimCost,
        id,
        mode,
        updateClaimCostDistribution,
      ],
    );

    const costTypeOptions = useCostTypeOptions(subClaims);

    return (
      <Modal
        centered
        width={Boolean(selectedCostType) ? 1280 : 600}
        visible={visible}
        destroyOnClose
        onOk={handleSubmit(onSubmit)}
        title={t('bo.orderClaims.processing.popUp.addCost.title')}
        onCancel={hideModalV2}
        okButtonProps={{
          hidden: mode === 'view',
          loading:
            distributeRetailClaimCostLoading ||
            updateClaimCostDistributionLoading,
        }}
        cancelText={
          mode === 'view'
            ? t('bo.orderClaims.processing.popUp.addCost.close.cta')
            : t('bo.orderClaims.processing.popUp.addCost.discard.cta')
        }
        okText={
          <span data-qa-selector="submitButton">
            {t('bo.orderClaims.processing.popUp.addCost.save.cta')}
          </span>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)} className={cn.form}>
          <CostForm
            mode={mode}
            setValue={setValue}
            costTypeOptions={costTypeOptions}
            approvedCostMinorUnits={approvedCostMinorUnits}
            subClaimsToShow={subClaimsForDistribution}
            control={control}
            currencyCode={currencyCode}
          />
        </form>
      </Modal>
    );
  },
);
