import { OptionModel } from '@retail/backoffice-ui';
import { eq, head, map, reduce } from 'lodash/fp';
import { TFunction } from 'react-i18next';

import {
  APPROVAL_LEVEL,
  CLAIM_SOURCE_TYPES,
  COST_STATUS,
  CUSTOMER_DECISION,
  ORDER_CLAIMS_STATUSES,
} from './constants';

import { RetailClaimCostProjection } from '@/apollo/gql-types';

export const getOrderClaimsStatus = (states: ORDER_CLAIMS_STATUSES[]) => {
  if (states.length === 1) {
    return eq(head(states), ORDER_CLAIMS_STATUSES.CLOSED)
      ? ORDER_CLAIMS_STATUSES.CLOSED
      : ORDER_CLAIMS_STATUSES.OPEN;
  } else if (states.length > 1) {
    return ORDER_CLAIMS_STATUSES.OPEN;
  }
  return ORDER_CLAIMS_STATUSES.NONE;
};

export const translateOptions = (
  options: OptionModel[],
  t: TFunction<'translation', undefined>,
) =>
  map((option) => ({ ...option, label: t(option.label as string) }), options);

export const getClaimTotalCost = (costs: RetailClaimCostProjection[]) =>
  reduce(
    (sum, cost) =>
      cost.status !== COST_STATUS.CANCELED &&
      [
        CUSTOMER_DECISION.OFFER_ACCEPTED,
        CUSTOMER_DECISION.NOT_REQUIRED,
      ].includes(cost.customerDecision as CUSTOMER_DECISION) &&
      [cost.approvalLevel1, cost.approvalLevel2, cost.approvalLevel3].includes(
        APPROVAL_LEVEL.APPROVED,
      )
        ? sum + cost.costMinorUnits
        : sum,
    0,
    costs,
  );

export const addCommentProps = (text: string, visibleForCustomer = false) => ({
  text,
  visibleForCustomer,
  sourceType: CLAIM_SOURCE_TYPES.BACK_OFFICE,
});

export const getCreateCommentPayload = ({
  subClaimId,
  comment,
}: {
  subClaimId?: string;
  comment: string;
}) => {
  if (comment) {
    return {
      subClaimId,
      retailSubClaimComment: addCommentProps(comment),
    };
  }
};

interface CreateCommentsPayloadProps {
  visibleForCustomer: string;
}

export const createCommentsPayload = ({
  visibleForCustomer,
}: CreateCommentsPayloadProps) => {
  if (visibleForCustomer) {
    return [addCommentProps(visibleForCustomer, true)];
  }
  return [];
};

// hacky way hence name returned from the BE includes path to translations
export const isTechnicalMechanicalClaim = (claimGroup = '') =>
  claimGroup.includes('Technical/Mechanical');

export const allowOnlyNumbersAndPunctuation = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (!/[0-9\,\.]/.test(event.key)) {
    event.preventDefault();
  }
};

export const getPercentageOfValue = (
  value: number,
  percentage: number,
  precision = 2,
) => +((value / 100) * percentage).toFixed(precision);
