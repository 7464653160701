import { Control, useController } from 'react-hook-form';

import { MileageInput } from '../overview/content/additional-info/mileage-input';

import { ClaimCreateFormValues } from '.';

import { FIELD_LABEL_COL } from '@/constants/common';

export const MileageControl = ({
  control,
}: {
  control: Control<ClaimCreateFormValues>;
}) => {
  const { field: mileageField } = useController({
    control,
    rules: { required: true, min: 0 },
    name: 'carDetails.mileage',
  });
  return (
    <MileageInput
      required
      labelCol={FIELD_LABEL_COL.span}
      value={mileageField.value}
      onChange={mileageField.onChange}
    />
  );
};
