import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { useTranslation } from 'react-i18next';

import { Entity } from '../../types';

import { ClaimDueDate } from '@/components/ClaimDueDate';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
import { CLAIM_STATES } from '@/pages/claims/constants';

const REFETCH_QUERIES = ['SearchRetailClaims'];

interface Props {
  claim: Entity;
}

export function DueDate({ claim }: Props) {
  const { t } = useTranslation();
  const { isAllowed } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_UPDATE_CLAIM_STATE_DUE_DATE,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const isClosed = claim?.state === CLAIM_STATES.CLOSED;

  return (
    <div style={{ width: 200 }}>
      <ClaimDueDate
        label={t('bo.claimsOverview.dueDate')}
        claimId={claim?.id}
        defaultValue={isClosed ? null : claim?.stateDueDate}
        disabled={!isAllowed || isClosed}
        refetchQueries={REFETCH_QUERIES}
      />
    </div>
  );
}
