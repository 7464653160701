import { SelectControlled } from '@retail/backoffice-ui';
import { CurrencyCodes } from '@retail/currency';
import { Alert, Col, Row } from 'antd';
import { memo, useEffect, useMemo } from 'react';
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  COST_TYPE,
  COST_TYPE_OPTIONS,
  WARRANTY_OPTIONS,
} from '../../../../constants';
import { translateOptions } from '../../../../utils';

import { CostAmountControl } from './CostAmountControl';
import { OrderItemSelect } from './order-item-select';
import cn from './styles.less';
import { CostFormValues } from './types';

import { FIELD_LABEL_COL } from '@/constants/common';

const costTypesWithOrderItem = [
  COST_TYPE.OPTICAL_DAMAGES_DIRTY_CAR_NOT_REPAIRED,
  COST_TYPE.COMPENSATION_WRONG_CAR_DESCRIPTION_MISSING_CAR_FEATURES,
];

interface CostFormProps {
  setValue: UseFormSetValue<CostFormValues>;
  errors: FieldErrors<CostFormValues>;
  currencyCode: CurrencyCodes;
  isSubmitted: boolean;
  control: Control<CostFormValues>;
}

export const CostForm = memo<CostFormProps>(
  ({ control, currencyCode, setValue }) => {
    const { t } = useTranslation();
    const costTypeOptions = useMemo(
      () => translateOptions(COST_TYPE_OPTIONS, t),
      [t],
    );
    const warrantyOptions = useMemo(
      () => translateOptions(WARRANTY_OPTIONS, t),
      [t],
    );

    const selectedCostType = useWatch({ control, name: 'costType' });
    const shouldShowOrderItemField =
      costTypesWithOrderItem.includes(selectedCostType);

    useEffect(() => {
      if (!shouldShowOrderItemField) {
        setValue('orderItemId', null);
      }
    }, [setValue, shouldShowOrderItemField]);

    return (
      <div>
        <Row>
          <Col span={24} data-qa-selector="costType">
            <SelectControlled
              labelCol={FIELD_LABEL_COL}
              required
              allowClear
              label={t(
                'bo.orderClaims.processing.popUp.addCost.costAmount.title',
              )}
              options={costTypeOptions}
              placeholder={t(
                'bo.orderClaims.processing.popUp.addCost.dropdown.placeholder',
              )}
              controllerProps={{
                name: 'costType',
                control,
              }}
            />
          </Col>
        </Row>
        {shouldShowOrderItemField && (
          <Row>
            <Col span={24} data-qa-selector="warranty">
              <OrderItemSelect
                onChange={(value) =>
                  setValue('orderItemId', value, {
                    shouldValidate: true,
                  })
                }
                control={control}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24} data-qa-selector="warranty">
            <SelectControlled
              labelCol={FIELD_LABEL_COL}
              required
              allowClear
              label={t(
                'bo.orderClaims.processing.popUp.addCost.warrantyType.title',
              )}
              options={warrantyOptions}
              placeholder={t(
                'bo.orderClaims.processing.popUp.addCost.dropdown.placeholder',
              )}
              controllerProps={{
                name: 'warranty',
                control,
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <CostAmountControl currencyCode={currencyCode} control={control} />
          </Col>
        </Row>

        <Row style={{ marginTop: '15px' }}>
          <Col span={24}>
            <Alert
              type="warning"
              className={cn.warning}
              message={t('bo.orderClaims.processing.popUp.addCost.note')}
            />
          </Col>
        </Row>
      </div>
    );
  },
);
