import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ServerError,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';

import typePolicies from './policies';

function getCookieByName(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

const httpLink = createHttpLink({
  uri: `/${process.env.API_URL}`,
});

// NOTE Prepare common headers for all requests
const authLink = setContext((operation, { headers }) => {
  // NOTE get the authentication access token from cookies if it exists
  const accessToken = getCookieByName('t');
  // NOTE return the headers to the context so httpLink can read them
  return {
    headers: {
      accept: 'application/json, text/plain, */*',
      'access-domain': process.env.SCHEMA_ACCESS_DOMAIN,
      'content-type': 'application/json;charset=UTF-8',
      authorization: `${process.env.SCHEMA_AUTH_TITLE} ${accessToken}`,
      ...headers,
    },
  };
});

const orderManagementAuthLink = setContext(() => {
  // NOTE get the authentication access token from cookies if it exists
  const accessToken = getCookieByName('t');
  // NOTE return the headers to the context so httpLink can read them
  return {
    headers: {
      accept: 'application/json, text/plain, */*',
      'access-domain': 'order.management',
      'content-type': 'application/json;charset=UTF-8',
      authorization: `${process.env.SCHEMA_AUTH_TITLE} ${accessToken}`,
    },
  };
});

// NOTE Apollo client
export const client = new ApolloClient({
  link: ApolloLink.from([authLink, onError(handleErrors), httpLink]),
  cache: new InMemoryCache({
    typePolicies,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export const orderManagementClient = new ApolloClient({
  link: ApolloLink.from([
    orderManagementAuthLink,
    onError(handleErrors),
    httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

function handleErrors({ networkError, operation, forward }: ErrorResponse) {
  if ((networkError as ServerError)?.statusCode === 401) {
    const accessToken = getCookieByName('t');
    operation.setContext({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return forward(operation);
  }
}
