import { useTranslation } from 'react-i18next';

import { InfoCard } from '../InfoCard';

import { MileageDto, RetailWarrantyProjection } from '@/apollo/gql-types';
import { InlineKeyValueRow } from '@/components/InlineKeyValueRow';
import { formatDate } from '@/helpers/date';
import { formatMileage } from '@/utils/i18n/formatMileage';

interface Props {
  warranty: RetailWarrantyProjection;
  maxAllowedMileage: Pick<MileageDto, 'distance' | 'unit'>;
  warrantyUpgradeDate?: string;
  loading: boolean;
  warrantyExpiryDate: string;
}

export function WarrantyDetails({
  warranty,
  loading,
  maxAllowedMileage,
  warrantyUpgradeDate,
  warrantyExpiryDate,
}: Props) {
  const { t } = useTranslation();

  const maxAllowedMileageFormatted = maxAllowedMileage
    ? formatMileage(maxAllowedMileage)
    : null;

  return (
    <InfoCard
      loading={loading}
      size="small"
      title={t('bo.orderClaims.orderSummary.title.warrantyDetails')}
    >
      <InlineKeyValueRow
        qaSelector="warrantyType"
        label={t('bo.orderClaims.orderSummary.title.warrantyType')}
        value={warranty?.internalReferenceName}
      />
      {warrantyUpgradeDate ? (
        <InlineKeyValueRow
          qaSelector="warrantyUpgradeDate"
          label={t('bo.orderClaims.orderSummary.title.warrantyUpgradeDate')}
          value={formatDate(warrantyUpgradeDate)}
        />
      ) : null}
      <InlineKeyValueRow
        qaSelector="warrantyIssuer"
        label={t('bo.orderClaims.orderSummary.title.warrantyIssuer')}
        value={warranty?.partnerName}
      />
      <InlineKeyValueRow
        qaSelector="warrantyIssuer"
        label={t('bo.orderClaims.orderSummary.title.warrantyExpiry')}
        value={warrantyExpiryDate}
      />
      <InlineKeyValueRow
        qaSelector="warrantyIssuer"
        label={t('bo.orderClaims.orderSummary.title.maxAllowedMileage')}
        value={maxAllowedMileageFormatted}
      />
    </InfoCard>
  );
}
