import { Table, Typography } from 'antd';
import { CurrencyCodes } from '@retail/currency';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { formatCurrency } from '@retail/backoffice-ui';

import { CostFormV2Values } from './types';
import { SubClaimVm, useSubClaimCostsColumns } from './useSubclaimCostsColumns';

import { CostEstimateApplicability } from '@/apollo/gql-types';

type SubclaimsCostsTableProps = {
  disabled?: boolean;
  data: SubClaimVm[];
  setValue: UseFormSetValue<CostFormV2Values>;
  control: Control<CostFormV2Values>;
  currencyCode: CurrencyCodes;
};

export const SubClaimsCostsTable = ({
  data,
  control,
  currencyCode,
  setValue,
  disabled,
}: SubclaimsCostsTableProps) => {
  const shouldHideCostEstimate = data.every(
    (item) => item.costEstimateApplicability === CostEstimateApplicability.Na,
  );

  const columns = useSubClaimCostsColumns({
    control,
    currencyCode,
    setValue,
    disabled,
    shouldHideCostEstimate,
  });

  const costs = useWatch({ control, name: 'costs' });

  useEffect(() => {
    data.forEach((row, idx) => {
      setValue(`costs.${idx}.subClaimId`, row.subClaimId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  return (
    <Table
      size="small"
      rowKey="key"
      pagination={false}
      dataSource={data}
      columns={columns}
      summary={() => {
        const totalCostEstimateMinorUnits = costs.reduce(
          (prev, curr) => prev + (curr.costEstimateAmountMinorUnits ?? 0),
          0,
        );

        const totalCompensationMinorUnits = costs.reduce(
          (prev, curr) => prev + (curr.compensationAmountMinorUnits ?? 0),
          0,
        );
        return (
          <Table.Summary.Row>
            <Table.Summary.Cell index={1} />
            <Table.Summary.Cell index={2} />
            {shouldHideCostEstimate ? null : (
              <Table.Summary.Cell index={3}>
                <Typography.Text data-qa-selector="totalCostEstimateMinorUnits">
                  Total estimate:{' '}
                  {formatCurrency({
                    amountMinorUnits: totalCostEstimateMinorUnits,
                    currencyCode,
                  })}
                </Typography.Text>
              </Table.Summary.Cell>
            )}
            <Table.Summary.Cell index={4} />
            <Table.Summary.Cell index={5}>
              <Typography.Text
                strong
                data-qa-selector="totalCompensationMinorUnits"
              >
                Total compensation:{' '}
                {formatCurrency({
                  amountMinorUnits: totalCompensationMinorUnits,
                  currencyCode,
                })}
              </Typography.Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
};
