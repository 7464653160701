import { find } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Entity } from '../../types';

import {
  RetailClaimAreaProjection,
  RetailClaimCarPartProjection,
  RetailClaimDamageProjection,
} from '@/apollo/gql-types';

export interface SubClaimsFieldData {
  id: number;
  number: string;
  title: string;
}

export function useSubclaimCostMetadata(claim: Entity): SubClaimsFieldData[] {
  const { t } = useTranslation();

  return useMemo(
    () =>
      claim?.subClaims.map(({ id, number, areaId, carPartId, damageId }) => {
        const defaultValue = {
          id,
          number,
          title: '',
        };

        const area = find<RetailClaimAreaProjection>({ id: areaId })(
          claim?.claimGroup?.areas,
        );

        if (!area) {
          return defaultValue;
        }

        const carPart = find<RetailClaimCarPartProjection>({ id: carPartId })(
          area?.carParts,
        );

        if (!carPart) {
          return {
            ...defaultValue,
            title: `${t(area?.name)}`,
          };
        }

        const damage = find<RetailClaimDamageProjection>({ id: damageId })(
          carPart?.damages,
        );

        if (!damage) {
          return {
            ...defaultValue,
            title: `${t(area?.name)} - ${t(carPart?.name)}`,
          };
        }

        return {
          ...defaultValue,
          title: `${t(area?.name)} - ${t(carPart?.name)} - ${t(damage?.name)}`,
        };
      }),
    [claim, t],
  );
}
