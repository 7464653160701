import { SelectControlled } from '@retail/backoffice-ui';
import { prop } from 'lodash/fp';
import { useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CostFormValues } from './types';
import { useOrderItemOptions } from './useOrderItemOptions';

import { FIELD_LABEL_COL } from '@/constants/common';

export const OrderItemSelect = ({
  control,
  onChange,
  disabled,
}: {
  disabled?: boolean;
  control: Control<Pick<CostFormValues, 'orderItemId'>>;
  onChange: (value: CostFormValues['orderItemId']) => void;
}) => {
  const { orderId } = useParams();
  const orderItemsOptions = useOrderItemOptions(orderId);
  const { t } = useTranslation();

  const selectedOrderItemId = useWatch({ control, name: 'orderItemId' });
  useEffect(() => {
    const defaultOptionValue = orderItemsOptions.find(prop('isDefault'))?.value;
    if (!selectedOrderItemId && defaultOptionValue) {
      onChange(defaultOptionValue);
    }
  }, [onChange, orderItemsOptions, selectedOrderItemId]);

  return (
    <SelectControlled
      labelCol={FIELD_LABEL_COL}
      disabled={disabled}
      required
      label={t('bo.orderClaims.processing.popUp.addCost.orderItem.title', {
        defaultValue: 'Order Item',
      })}
      options={orderItemsOptions?.map((o) => ({
        label: o.label,
        value: o.value,
      }))}
      placeholder={t(
        'bo.orderClaims.processing.popUp.addCost.dropdown.placeholder',
      )}
      controllerProps={{
        name: 'orderItemId',
        control,
      }}
    />
  );
};
