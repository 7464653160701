import { makeVar, useReactiveVar } from '@apollo/client';
import { Button, Card, Modal } from 'antd';
import { SyntheticEvent, memo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SubclaimCreateCommentAndDocumentsForm } from '../SubclaimCreateCommentAndDocumentsForm';
import { useSearchRetailClaimsDocument } from '../hooks/useSearchRetailClaimsDocument';
import { Entity, ExtendedSubClaim } from '../overview/types';

import { useCreateSubclaimCommentAndDocuments } from './useCreateSubclaimCommentAndDocuments';

export const manageClaimSubmissionModal = makeVar<{
  visible?: boolean;
  subclaim?: ExtendedSubClaim;
  claim?: Entity;
}>({});

export const hideModal = () => manageClaimSubmissionModal({});
export const showModal = (claim: Entity, subclaim: ExtendedSubClaim) =>
  manageClaimSubmissionModal({
    claim,
    subclaim,
    visible: true,
  });

interface FormValue {
  newDocuments?: File[];
  comment?: string;
}

interface Props {
  retailCountry: string;
}

export const SubclaimCreateCommentAndDocumentsModal = memo<Props>(
  ({ retailCountry }) => {
    const { visible, subclaim, claim } = useReactiveVar(
      manageClaimSubmissionModal,
    );
    const { t } = useTranslation();
    const refetchClaims = useSearchRetailClaimsDocument();
    const createCommentAndDocuments = useCreateSubclaimCommentAndDocuments({
      subClaimId: subclaim?.id,
      retailCountry,
    });

    const {
      control,
      formState: { isSubmitting, errors },
      handleSubmit,
      reset,
    } = useForm<FormValue>({
      defaultValues: {},
      mode: 'all',
    });

    const [comment, newDocuments] = useWatch({
      control,
      name: ['comment', 'newDocuments'],
    });

    useEffect(() => reset({}), [reset, subclaim]);

    async function onHandleSubmit(event: SyntheticEvent) {
      await handleSubmit(async ({ comment, newDocuments }) => {
        await createCommentAndDocuments({ comment, newDocuments });
      })(event);

      await refetchClaims({ orderId: claim.order.id, claimId: claim.id });

      hideModal();
    }

    const isDisabled = !comment && !newDocuments?.length;

    return (
      <Modal
        centered
        visible={visible}
        width={1000}
        onCancel={hideModal}
        title={
          <strong>
            {t('bo.orderClaims.processing.table.commentsAndDocuments.title')}
          </strong>
        }
        footer={
          <>
            <Button type="default" onClick={hideModal}>
              <span data-qa-selector="cancel-subclaim-comment-and-document">
                {t('bo.orderClaims.form.cancelBtnText')}
              </span>
            </Button>
            <Button
              type="primary"
              loading={isSubmitting}
              disabled={isDisabled}
              onClick={onHandleSubmit}
            >
              <span data-qa-selector="update-subclaim-comment-and-document">
                {t('bo.orderClaims.form.saveBtnText')}
              </span>
            </Button>
          </>
        }
      >
        <Card
          title={
            <>
              <span>{`${t('bo.orderClaims.form.sectionTitle')}: `}</span>
              <strong>{subclaim?.number}</strong>
            </>
          }
        >
          <form onSubmit={onHandleSubmit}>
            <SubclaimCreateCommentAndDocumentsForm
              claim={claim}
              control={control}
              errors={errors}
            />
          </form>
        </Card>
      </Modal>
    );
  },
);
