import { InfoCircleOutlined } from '@ant-design/icons';
import { FormItem } from '@retail/backoffice-ui';
import { Col, Radio, Row, Tooltip } from 'antd';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type MechanicalClaimQuestionsFormGroupProps = {
  control: Control;
  disabled?: boolean;
};

export const MechanicalClaimQuestionsFormGroup = ({
  control,
  disabled,
}: MechanicalClaimQuestionsFormGroupProps) => {
  const timeDependencyTypeControllerProps = {
    name: 'timeDependencyType',
    control,
  };
  const { field: timeDependencyTypeField } = useController(
    timeDependencyTypeControllerProps,
  );

  const workTypeControllerProps: UseControllerProps<FieldValues, string> = {
    name: 'workType',
    control,
  };

  const { field: workTypeField } = useController(workTypeControllerProps);

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24} data-qa-selector="before-handover-field">
        <FormItem
          label={
            <>
              {t('bo.orderClaims.beforeOrAfterHandoverQuestion')}{' '}
              <Tooltip
                placement="top"
                title={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {t(
                      'bo.orderClaims.beforeOrAfterHandoverQuestion.description',
                    )}
                  </span>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </>
          }
          required
          controllerProps={timeDependencyTypeControllerProps}
        >
          <Radio.Group {...timeDependencyTypeField}>
            <Radio disabled={disabled} value="BEFORE_HANDOVER">
              {t('bo.orderClaims.yes')}
            </Radio>
            <Radio disabled={disabled} value="AFTER_HANDOVER">
              {t('bo.orderClaims.no')}
            </Radio>
          </Radio.Group>
        </FormItem>
      </Col>
      <Col span={20} data-qa-selector="repair-maintenance-field">
        <FormItem
          disabled={disabled}
          required
          label={t('bo.orderClaims.workTypeQuestion')}
          controllerProps={workTypeControllerProps}
        >
          <Radio.Group {...workTypeField}>
            <Radio disabled={disabled} value="REPAIR">
              {t('bo.orderClaims.workType.repair')}
            </Radio>
            <Radio disabled={disabled} value="MAINTENANCE">
              {t('bo.orderClaims.workType.maintenance')}
            </Radio>
          </Radio.Group>
        </FormItem>
      </Col>
    </Row>
  );
};
