import { useMemo } from 'react';

import { MileageInputWithSaveAndCancel } from './mileage-input-with-save-and-cancel';

import { formatDateTime } from '@/helpers/date';
import {
  useCreateClaimCarDetailsMutation,
  useUpdateClaimCarDetailsMutation,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

type Props = {
  claimId: string;
  mileage: number | undefined;
  lastEditedBy?: { firstName?: string; lastName?: string };
  lastEditedOn?: string;
  disabled?: boolean;
};
const mutationOptions = {
  ...REQUEST_BASE_OPTIONS,
  awaitRefetchQueries: true,
  refetchQueries: ['SearchRetailClaims'],
};
export const ClaimMileage = ({
  claimId,
  mileage,
  lastEditedBy,
  lastEditedOn,
  disabled,
}: Props) => {
  const [updateClaimCarDetails, { loading: updateClaimCarDetailsLoading }] =
    useUpdateClaimCarDetailsMutation(mutationOptions);
  const [createClaimCarDetails, { loading: createClaimCarDetailsLoading }] =
    useCreateClaimCarDetailsMutation(mutationOptions);
  const mileageLoading =
    updateClaimCarDetailsLoading || createClaimCarDetailsLoading;
  const saveMileage = async (value: number) => {
    if (lastEditedOn) {
      await updateClaimCarDetails({
        variables: {
          claimId,
          carDetails: {
            mileage: value,
          },
        },
      });
    } else {
      await createClaimCarDetails({
        variables: {
          claimId,
          carDetails: {
            mileage: value,
          },
        },
      });
    }
  };

  const mileageLastEditedFormattedMessage = useMemo(() => {
    if (lastEditedOn) {
      const modifiedByFormatted = lastEditedBy
        ? `${lastEditedBy.firstName} ${lastEditedBy.lastName}`
        : 'Unknown';
      return `${modifiedByFormatted} ${formatDateTime(lastEditedOn)}`;
    }
    return '';
  }, [lastEditedBy, lastEditedOn]);

  return (
    <MileageInputWithSaveAndCancel
      lastEditedInfo={mileageLastEditedFormattedMessage}
      value={mileage}
      onSave={saveMileage}
      loading={mileageLoading}
      disabled={disabled}
    />
  );
};
