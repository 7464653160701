import { Button, Col, Collapse, Row } from 'antd';
import { isNull } from 'lodash/fp';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ORDER_CLAIMS_STATUSES } from '../../../constants';
import { Entity } from '../../types';

import cn from './styles.less';
import { WorkshopDetailsModal, showModal } from './workshop-details-modal';

import { formatDate } from '@/helpers/date';

export interface WorkshopDetailsProps {
  id: Entity['id'];
  workshopDetails: Entity['workshopDetails'];
  orderClaimStatus: ORDER_CLAIMS_STATUSES;
}

export const WorkshopDetails = memo<WorkshopDetailsProps>(
  ({ id, workshopDetails = null, orderClaimStatus }) => {
    const { t } = useTranslation();

    const isCarPickedUp = useMemo(() => {
      if (isNull(workshopDetails) || isNull(workshopDetails?.carPickedUp)) {
        return '-';
      }

      return workshopDetails?.carPickedUp
        ? t(
            'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpStatus.yes',
          )
        : t(
            'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpStatus.no',
          );
    }, [t, workshopDetails]);

    const carPickedUpOnDate = useMemo(() => {
      if (isNull(workshopDetails) || isNull(workshopDetails?.carPickedUpOn)) {
        return '-';
      }
      return formatDate(workshopDetails?.carPickedUpOn) || 'N/A';
    }, [workshopDetails]);

    return (
      <Collapse expandIconPosition="start" defaultActiveKey={['1']}>
        <Collapse.Panel
          key="1"
          header={
            <h3 data-qa-selector="workshopDetails" className={cn.title}>
              {t('bo.orderClaims.processing.popUp.workshopDetails.title')}
            </h3>
          }
        >
          <div className={cn.buttonWrap}>
            <Button
              type="primary"
              onClick={() => showModal()}
              data-qa-selector="add-workshop-details-button"
              disabled={orderClaimStatus === ORDER_CLAIMS_STATUSES.CLOSED}
            >
              {t(
                workshopDetails
                  ? 'bo.orderClaims.processing.popUp.workshopDetails.edit'
                  : 'bo.orderClaims.processing.popUp.workshopDetails.addCTA',
              )}
            </Button>
          </div>
          <Row>
            <Col md={16}>
              <div
                className={cn.workshopTable}
                data-qa-selector="workshop-details-table"
              >
                <div className={cn.workshopTableRow}>
                  <div className={cn.workshopTableHeading}>
                    {t(
                      'bo.orderClaims.processing.popUp.workshopDetails.workshopAddress.title',
                    )}
                  </div>
                  <div
                    className={cn.workshopTableBody}
                    data-qa-selector="workshop-address"
                  >
                    {workshopDetails?.address || '-'}
                  </div>
                </div>
                <div className={cn.workshopTableRow}>
                  <div className={cn.workshopTableHeading}>
                    {t(
                      'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpStatus.title',
                    )}
                  </div>
                  <div
                    className={cn.workshopTableBody}
                    data-qa-selector="car-picked-up-from-workshop"
                  >
                    {isCarPickedUp}
                  </div>
                </div>
                <div className={cn.workshopTableRow}>
                  <div className={cn.workshopTableHeading}>
                    {t(
                      'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpDate.title',
                    )}
                  </div>
                  <div
                    className={cn.workshopTableBody}
                    data-qa-selector="date-workshop-picked-up"
                  >
                    {carPickedUpOnDate}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <WorkshopDetailsModal claimId={id} workshop={workshopDetails} />
        </Collapse.Panel>
      </Collapse>
    );
  },
);
