import { setSeconds } from 'date-fns';
import { every, find, isEmpty, isNull, pick, reduce, some } from 'lodash/fp';
import { TFunction } from 'react-i18next';

import { COST_STATUS, COST_TYPE } from '../constants';

import { Entity } from './types';

import {
  RetailClaimCostProjection,
  RetailClaimProjection,
  RetailSubClaimDocumentProjection,
  RetailSubClaimProjection,
} from '@/apollo/gql-types';

export const validateCloseState = (
  claim: RetailClaimProjection,
  t: TFunction<'translation', undefined>,
): string => {
  if (isAnyActiveCost(claim.costs)) {
    return t(
      'bo.orderClaims.processing.approvalCustomerDecisionPending.warning',
    );
  }
  const totalSubClaims = claim?.subClaims.reduce(
    (sum, subclaim) => sum + subclaim.costMinorUnits,
    0,
  );

  // TODO isAllCostsRejected(claim.costs) && totalSubClaims === 0
  if (isAllCostsRejected(claim.costs)) {
    return;
  }

  const acceptedCost = claim?.costs.filter((cost) => !isCostRejected(cost));
  const totalAcceptedCost = acceptedCost.reduce(
    (sum, cost) => sum + cost.costMinorUnits,
    0,
  );

  if (totalAcceptedCost !== totalSubClaims) {
    return t('bo.orderClaims.processing.subclaimEmpty.warning');
  }
};

export const isCostAccepted = (cost: RetailClaimCostProjection) =>
  cost.status === COST_STATUS.COMPLETED;

export const isSubClaimsWaitingApproval = (
  costs: RetailClaimCostProjection[],
) => {
  if (isEmpty(costs)) {
    return false;
  }

  return every<RetailClaimCostProjection>(
    ({ status }) => status === COST_STATUS.ACTIVE,
  )(costs);
};

export const canCloseClaim = (claim: Entity, isClaimV2Flow: boolean) => {
  const { subClaims, claimGroup } = claim ?? {};
  if (isEmpty(subClaims) || isEmpty(claimGroup)) {
    return false;
  }

  return every<RetailSubClaimProjection>(
    ({ areaId, carPartId, damageId, liablePartyType, groupData }) => {
      if (!liablePartyType) {
        return false;
      }

      if (isClaimV2Flow) {
        const selectedArea = groupData?.area;
        if (!selectedArea) {
          return false;
        }

        const selectedPart = selectedArea.carPart;
        if (!selectedPart) {
          return false;
        }

        const selectedDamage = selectedPart.damage;
        if (!selectedDamage) {
          return false;
        }

        return true;
      }

      const areas = claimGroup?.areas;
      if (isEmpty(areas)) {
        return true;
      }
      const area = find({ id: areaId }, areas);
      if (isEmpty(area)) {
        return false;
      }
      const parts = area?.carParts;
      if (isEmpty(parts)) {
        return true;
      }
      const part = find({ id: carPartId }, parts);

      if (isEmpty(part)) {
        return false;
      }

      const damages = part?.damages;
      if (isEmpty(damages)) {
        return true;
      }
      const damage = find({ id: damageId }, damages);
      if (!damage) {
        return false;
      }

      return true;
    },
  )(subClaims);
};

export const isAnyActiveCost = (costs: RetailClaimCostProjection[]) =>
  some((cost) => !isCostRejected(cost) && !isCostAccepted(cost))(costs);

const isCostRejected = (cost: RetailClaimCostProjection) =>
  cost.status === COST_STATUS.CANCELED;

export const isAllCostsRejected = (costs: RetailClaimCostProjection[]) =>
  every((cost) => isCostRejected(cost))(costs);

const isCostsNotApproved = (costs: RetailClaimCostProjection[]) => {
  return every<RetailClaimCostProjection>(
    ({ status }) => status !== COST_STATUS.CANCELED,
  )(costs);
};

export const isCostTypeWorkshop = (costs: RetailClaimCostProjection[]) =>
  find(({ costType }) => costType === COST_TYPE.WORKSHOP, costs);

export const isWorkshopDetailsIncomplete = ({
  workshopDetails,
  costs,
}: RetailClaimProjection) => {
  if (!isCostTypeWorkshop(costs)) {
    return false;
  }

  if (isCostTypeWorkshop(costs) && isNull(workshopDetails)) {
    return true;
  }

  if (isNull(workshopDetails)) {
    return !isCostsNotApproved(costs);
  }

  return !every(
    (el) => !isNull(el),
    pick(['address', 'carPickedUp', 'carPickedUpOn'], workshopDetails),
  );
};

export interface IGroupedDocument
  extends Pick<
    RetailSubClaimDocumentProjection,
    'createdBy' | 'createdOn' | 'sourceType' | '__typename'
  > {
  documents?: RetailSubClaimDocumentProjection[];
}

export const groupDocumentsByTime = (
  documents: RetailSubClaimDocumentProjection[],
): IGroupedDocument[] => {
  const documentsTimeMap = reduce<
    RetailSubClaimDocumentProjection,
    Record<string, IGroupedDocument>
  >((acc: Record<string, IGroupedDocument>, current) => {
    const { createdOn, createdBy, __typename } = current;
    // Remove seconds and set them to common value (59s) and thus grouping them by minute
    const createdOnInMinutes = setSeconds(
      new Date(createdOn),
      59,
    ).toISOString();
    const groupedDocumentsItem: IGroupedDocument = {
      createdOn: createdOnInMinutes,
      createdBy,
      __typename,
      documents: [],
    };

    if (!acc[createdOnInMinutes]) {
      groupedDocumentsItem.documents.push(current);
      acc[createdOnInMinutes] = groupedDocumentsItem;

      return acc;
    }

    acc[createdOnInMinutes].documents.push(current);

    return acc;
  }, {})(documents);

  return Object.values(documentsTimeMap);
};
