import { Entity } from '../../../types';

import { ActionInfo } from './ActionInfo';
import { CarInfo } from './CarInfo';
import { OrderInfo } from './OrderInfo';
import cn from './styles.less';
import { OrderNumber } from './OrderInfo/OrderNumber';

import { AdItemProjection, OrderProjection } from '@/apollo/gql-types';
import { TClaimOrderDetails } from '@/pages/claims/selectors';

interface Props {
  adId: AdItemProjection['id'];
  order: Pick<OrderProjection, 'id' | 'orderNumber' | 'stockNumber'>;
  claimOrderDetails: TClaimOrderDetails;
  claim: Entity;
}

export function OrderDetails({ adId, claimOrderDetails, order, claim }: Props) {
  return (
    <div className={cn.content}>
      <OrderInfo adId={adId} order={order} />
      <ActionInfo claim={claim} />
      <CarInfo claimOrderDetails={claimOrderDetails} />
      {claimOrderDetails.linkedOrderNumber &&
        claimOrderDetails.linkedOrderId && (
          <OrderNumber
            orderId={claimOrderDetails.linkedOrderId}
            orderNumber={claimOrderDetails.linkedOrderNumber}
            label="bo.orderClaims.processing.additionalOrderNumber"
          />
        )}
    </div>
  );
}
