import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MileageInput } from './mileage-input';

const isValidMileage = (val: number) => typeof val === 'number';

type MileageInputWithSaveAndCancelProps = {
  loading?: boolean;
  disabled?: boolean;
  lastEditedInfo?: string;
  labelCol?: number;
  value: number | undefined;
  onSave: (val: number) => Promise<void>;
};

export const MileageInputWithSaveAndCancel = ({
  lastEditedInfo,
  labelCol = 3,
  value: lastSavedValue,
  onSave,
  loading,
  disabled,
}: MileageInputWithSaveAndCancelProps) => {
  const { t } = useTranslation();

  const isSavedMileageValid = typeof lastSavedValue === 'number';
  const [editing, setEditing] = useState(!isSavedMileageValid);
  const [localValue, setValue] = useState(lastSavedValue);

  const [error, setError] = useState('');

  const isValid = isValidMileage(localValue);
  useEffect(() => {
    setError(isValid ? '' : t('bo.orderClaims.form.validation.canNotBeEmpty'));
  }, [isValid, lastSavedValue, localValue, t]);
  const handleSave = async () => {
    if (isValid) {
      await onSave(localValue);
      setEditing(false);
    }
  };

  const handleCancel = () => {
    setValue(lastSavedValue);
    setEditing(false);
  };

  return (
    <MileageInput
      status={error ? 'error' : undefined}
      errorMessage={error}
      suffix={
        lastEditedInfo ? (
          <Typography.Text italic>{lastEditedInfo}</Typography.Text>
        ) : null
      }
      labelCol={labelCol}
      value={localValue}
      loading={loading}
      onChange={setValue}
      readonly={!editing}
      disabled={disabled}
      addonAfter={
        disabled ? null : editing ? (
          <Space>
            <CheckOutlined disabled={disabled} onClick={handleSave} />
            <CloseOutlined disabled={disabled} onClick={handleCancel} />
          </Space>
        ) : (
          <EditOutlined disabled={disabled} onClick={() => setEditing(true)} />
        )
      }
    />
  );
};
