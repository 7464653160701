import { reduce } from 'lodash/fp';
import { useMemo } from 'react';

import { CLAIM_COST_STATUSES } from '../../../../constants';
import { Entity, ExtendedSubClaim } from '../../../types';

import { FormatCurrencyOptions } from '@/utils/i18n/useFormatCurrency';

export function useCostCalculations(claim: Entity) {
  const claimTotalCost = useMemo(
    () =>
      reduce(
        (
          result: FormatCurrencyOptions,
          { costMinorUnits, status, costCurrencyCode },
        ) => {
          if (status !== CLAIM_COST_STATUSES.COMPLETED) {
            return result;
          }

          return {
            currencyCode: costCurrencyCode,
            amountMinorUnits: result.amountMinorUnits + costMinorUnits,
          };
        },
        { amountMinorUnits: 0 },
      )(claim?.costs),
    [claim?.costs],
  );

  const allocatedSubclaimCosts = useMemo(
    () =>
      reduce<ExtendedSubClaim, FormatCurrencyOptions>(
        (result: FormatCurrencyOptions, { costMinorUnits, costCurrencyCode }) =>
          ({
            currencyCode: costCurrencyCode,
            amountMinorUnits: result.amountMinorUnits + costMinorUnits,
          } as FormatCurrencyOptions),
        { amountMinorUnits: 0 },
      )(claim?.subClaims),
    [claim],
  );

  return useMemo(() => {
    const { amountMinorUnits: claimTotalCostAmount } = claimTotalCost;
    const { amountMinorUnits: allocatedSubclaimCostsAmount } =
      allocatedSubclaimCosts;
    const remainingClaimTotalCostAmount =
      claimTotalCostAmount - allocatedSubclaimCostsAmount;
    const remainingClaimTotalCost = {
      currencyCode: claimTotalCost.currencyCode,
      amountMinorUnits:
        remainingClaimTotalCostAmount > 0 ? remainingClaimTotalCostAmount : 0,
    };

    return {
      claimTotalCost,
      remainingClaimTotalCost,
    };
  }, [claimTotalCost, allocatedSubclaimCosts]);
}
