import { TextareaControlled } from '@retail/backoffice-ui';
import { Col, Row } from 'antd';
import { sumBy } from 'lodash/fp';
import { useCallback } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCanEditFields } from '../hooks/useCanEditFields';
import { Entity } from '../overview/types';

import { DropzoneControlled } from '@/components/form/dropzone';
import { FIELD_LABEL_COL } from '@/constants/common';

export const DOCUMENT_NAME = 'documents';
export const NEW_FOCUMENTS_NAME = 'newDocuments';
export const COMMENT_NAME = 'comment';
const maxFileSizeMB = 500;

interface Props {
  claim?: Entity;
  control: Control;
  errors: unknown;
}

export function SubclaimCreateCommentAndDocumentsForm({
  claim,
  control,
  errors,
}: Props) {
  const { t } = useTranslation();

  const [documents] = useWatch({
    control,
    name: [DOCUMENT_NAME],
  });
  const canEditFields = useCanEditFields(claim);

  const validateFilesSize = useCallback(
    (files) => {
      const newFilesSizeKB = sumBy('size', files) / 1024;
      const totalFileSizeMB =
        (newFilesSizeKB + sumBy('fileSizeKb')(documents?.backOffice)) / 1024;
      return totalFileSizeMB <= maxFileSizeMB;
    },
    [documents?.backOffice],
  );

  return (
    <Row gutter={20}>
      <Col span={24} md={12} data-qa-selector={NEW_FOCUMENTS_NAME}>
        <DropzoneControlled
          errors={errors}
          disabled={!canEditFields}
          maxSizeMB={maxFileSizeMB}
          qaSelector="documentUploadDropzone"
          label={t('bo.orderClaims.form.documentsLabel')}
          placeholder={t('bo.orderClaims.form.dropzonePlaceholder')}
          hint={`${t(
            'bo.orderClaims.form.dropzoneFileSizePlaceholder',
          )}: ${maxFileSizeMB} MB`}
          error={`${t(
            'bo.orderClaims.form.dropzoneSizeValidationErrorMsg',
          )} (${maxFileSizeMB} MB)`}
          controllerProps={{
            name: NEW_FOCUMENTS_NAME,
            control,
            rules: {
              validate: validateFilesSize,
            },
          }}
        />
      </Col>
      <Col span={24} md={12} data-qa-selector={COMMENT_NAME}>
        <TextareaControlled
          labelCol={FIELD_LABEL_COL}
          rows={4}
          maxLength={300}
          disabled={!canEditFields}
          label={t('bo.orderClaims.form.commentsLabel')}
          controllerProps={{
            name: COMMENT_NAME,
            control,
          }}
        />
      </Col>
    </Row>
  );
}
