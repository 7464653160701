import { useMemo } from 'react';

import { APPROVAL_LEVEL, CLAIM_STATES, CUSTOMER_DECISION } from '../constants';
import { Entity } from '../overview/types';

export function useIsSubClaimDisabled(claim: Entity) {
  return useMemo(() => {
    const isSomeClaimCostIsApproved = claim?.costs.some((cost) =>
      [cost.approvalLevel1, cost.approvalLevel2, cost.approvalLevel3].some(
        (status) => status === APPROVAL_LEVEL.APPROVED,
      ),
    );

    if (isSomeClaimCostIsApproved && claim?.state !== CLAIM_STATES.CLOSED) {
      return false;
    }

    return claim?.costs.some(
      (cost) =>
        ![cost.approvalLevel1, cost.approvalLevel2, cost.approvalLevel3].some(
          (status) => status === APPROVAL_LEVEL.PENDING,
        ) &&
        (cost.customerDecision !== CUSTOMER_DECISION.OFFER_NOT_SENT ||
          (cost.customerDecision as CUSTOMER_DECISION) ===
            CUSTOMER_DECISION.NOT_REQUIRED),
    );
  }, [claim?.costs, claim?.state]);
}
