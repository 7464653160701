import { List, TableColumnType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedSubClaim } from '../../../types';
import { CustomerComplaint } from '../../customer-complaints/Complaint';
import { UseComplaintsData } from '../../customer-complaints/hooks/useComplaintsDataV2';
import { ReasonsAndLiableParty } from '../reasons-and-liable-party';
import { CostColumn } from '../columns/CostColumn';
import { getListItemByType, LIST_ITEM_TYPE } from '../utils';

import {
  RetailClaimComplaintProjection,
  RetailSubClaimCommentProjection,
  RetailSubClaimDocumentProjection,
} from '@/apollo/gql-types';
import { DocumentViewerManager } from '@/components/DocumentViewer';

const BODY_DAMAGE_CATEGORY_ID = '08d8c188-f5f7-4b20-ac12-ec0c6f8ee840';
const PROCESS_DELAY_CATEGORY_ID = '08b68507-d48c-45f2-ba35-4048a359ca51';

interface Props {
  claimState: string;
  claimFlowVersion: string;
  claimGroupId: string;
  claimApprovedCostIds: string[];
  complaint: RetailClaimComplaintProjection;
}

export function useTableColumns({
  claimState,
  claimFlowVersion,
  claimGroupId,
  claimApprovedCostIds,
  complaint,
}: Props): TableColumnType<ExtendedSubClaim>[] {
  const { t } = useTranslation();
  const {
    area,
    damage,
    complaintNumber,
    namingVersion,
    complaintArea,
    complaintDamage,
    complaintPart,
  } = complaint || {};

  const subClaimTitle = useMemo(() => {
    const damageArea = [];

    if (namingVersion === 2) {
      if (claimGroupId === BODY_DAMAGE_CATEGORY_ID) {
        complaintArea &&
          damageArea.push(
            t(
              `bo.orderClaims.form.customerComplaints.area.${String(
                complaintArea,
              ).toUpperCase()}`,
            ),
          );
        complaintDamage &&
          damageArea.push(
            t(
              `bo.orderClaims.form.customerComplaints.area.damage.${String(
                complaintDamage,
              ).toUpperCase()}`,
            ),
          );
      }
      if (
        ![BODY_DAMAGE_CATEGORY_ID, PROCESS_DELAY_CATEGORY_ID].includes(
          claimGroupId,
        )
      ) {
        complaintPart &&
          damageArea.push(
            t(`bo.orderClaims.submitReason.carParts.${String(complaintPart)}`),
          );
      }
    } else {
      if (area) {
        damageArea.push(
          t(
            `bo.orderClaims.form.customerComplaints.area.${String(
              area,
            ).toUpperCase()}`,
          ),
        );
      }
      if (damage) {
        damageArea.push(
          t(
            `bo.orderClaims.form.customerComplaints.area.damage.${String(
              damage,
            ).toUpperCase()}`,
          ),
        );
      }
    }

    return `${t(
      'bo.orderClaims.complaints.complaint',
    )} #${complaintNumber}. ${damageArea.join(' | ')}`;
  }, [
    area,
    claimGroupId,
    complaintArea,
    complaintDamage,
    complaintNumber,
    complaintPart,
    damage,
    namingVersion,
    t,
  ]);

  return useMemo(
    () => [
      {
        title: subClaimTitle,
        dataIndex: 'number',
        key: 'number',
        render: (_, { complaintsData }) => {
          return (
            <List
              dataSource={complaintsData}
              data-qa-selector="customerComplaintsList"
              renderItem={(complaint: UseComplaintsData) => {
                return (
                  <div>
                    <CustomerComplaint complaintData={complaint} />
                  </div>
                );
              }}
            />
          );
        },
      },
      {
        title: (
          <strong>
            {t('bo.orderClaims.processing.table.reasonsAndLiableParty.title')}
          </strong>
        ),
        key: 'reasonsAndLiableParty',
        width: '20%',
        render: (_, { reasons, liablePartyType }) => {
          return (
            <ReasonsAndLiableParty
              reasons={reasons}
              liablePartyType={liablePartyType}
              complaintNumber={complaintNumber}
            />
          );
        },
      },
      {
        title: (
          <strong>
            {t('bo.orderClaims.processing.table.commentsAndDocuments.title')}
          </strong>
        ),
        dataIndex: 'commentsAndDocuments',
        key: 'commentsAndDocuments',
        width: '20%',
        render: (
          value: Array<
            RetailSubClaimCommentProjection | RetailSubClaimDocumentProjection
          >,
          { documents },
        ) => (
          <DocumentViewerManager documents={documents.backOffice}>
            {value.map(({ __typename, ...item }, index) => {
              const Component = getListItemByType(
                __typename as LIST_ITEM_TYPE,
                claimState,
              );

              return <Component key={`${__typename}_${index}`} {...item} />;
            })}
          </DocumentViewerManager>
        ),
      },
      {
        title: (
          <strong>
            {t('bo.orderClaims.processing.table.subclaimCosts.title')}
          </strong>
        ),
        dataIndex: 'price',
        key: 'costs',
        width: '20%',
        render: (_, subClaim) => (
          <CostColumn
            claimFlowVersion={claimFlowVersion}
            subClaim={subClaim}
            claimApprovedCostIds={claimApprovedCostIds}
          />
        ),
      },
    ],
    [
      subClaimTitle,
      t,
      complaintNumber,
      claimState,
      claimFlowVersion,
      claimApprovedCostIds,
    ],
  );
}
