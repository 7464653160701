import { useTranslation } from 'react-i18next';

import { InfoSection } from './InfoSection';

import { RowInfo } from '@/components/RowInfo';
import { formatDate } from '@/helpers/date';
import { Entity } from '@/pages/claims/overview/types';

interface Props {
  claim: Entity;
}

export function ActionInfo({ claim }: Props) {
  const { t } = useTranslation();
  const { createdOn, closedOn } = claim ?? {};
  const createdDate = formatDate(createdOn);
  const closedDate = formatDate(closedOn);

  return (
    <InfoSection>
      <RowInfo
        qaSelectorValue="created-on"
        label={t('bo.orderClaims.createdOn')}
        value={createdDate}
      />
      <RowInfo
        qaSelectorValue="deleted-on"
        label={t('bo.orderClaims.dateClaimClosed')}
        value={closedDate}
      />
    </InfoSection>
  );
}
