import { filter, flow, map, orderBy, sortBy } from 'lodash/fp';

import { CLAIM_SOURCE_TYPES } from '../constants';

import { groupDocumentsByTime } from './helpers';
import { ExtendedSubClaim, ItemType } from './types';

import {
  RetailSubClaimCommentProjection,
  RetailSubClaimDocumentProjection,
  RetailSubClaimProjection,
} from '@/apollo/gql-types';

type SubEntity = {
  sourceType?: string;
  createdOn?: string;
};

const getEntitiesBySourceType =
  (sourceType: string) =>
  <T extends SubEntity>(entities: T[]) =>
    flow(
      filter({ sourceType }),
      sortBy<SubEntity>(({ createdOn }) => new Date(createdOn)),
    )(entities);

const filterByCustomer = getEntitiesBySourceType(CLAIM_SOURCE_TYPES.AUTO_HERO);
const filterByBoUser = getEntitiesBySourceType(CLAIM_SOURCE_TYPES.BACK_OFFICE);
const sortByCreatedOn = (entities: SubEntity[]) =>
  sortBy<SubEntity>(({ createdOn }) => new Date(createdOn))(entities);

type AdaptSubClaimsParams = {
  areaLabel: string;
  partLabel: string;
  damageLabel: string;
  getArea: (
    subClaim: RetailSubClaimProjection,
    areaId: string,
  ) => { name: string };
  getCarPart: (
    subClaim: RetailSubClaimProjection,
    areaId: string,
    carPartId: string,
  ) => { name: string };
  getDamage: (
    subClaim: RetailSubClaimProjection,
    areaId: string,
    carPartId: string,
    damageId: string,
  ) => { name: string };
};

export const adaptSubClaims = ({
  areaLabel,
  partLabel,
  damageLabel,
  getArea,
  getCarPart,
  getDamage,
}: AdaptSubClaimsParams) =>
  flow(
    orderBy<RetailSubClaimProjection>(['createdOnMillis'], ['asc']),
    map((subClaim: RetailSubClaimProjection): ExtendedSubClaim => {
      const { comments, documents, areaId, carPartId, damageId } = subClaim;
      const area = getArea(subClaim, areaId);
      const carPart = getCarPart(subClaim, areaId, carPartId); // ;
      const damage = getDamage(subClaim, areaId, carPartId, damageId); // ;
      const commentsObject: ItemType<RetailSubClaimCommentProjection> = {
        customer: filterByCustomer(comments),
        backOffice: filterByBoUser(comments),
      };
      const documentsObject: ItemType<RetailSubClaimDocumentProjection> = {
        customer: filterByCustomer(documents),
        backOffice: filterByBoUser(documents),
      };

      return {
        ...subClaim,
        reasons: filter(([, val]) => !!val, [
          [areaLabel, area?.name],
          [partLabel, carPart?.name],
          [damageLabel, damage?.name],
        ] as [string, string][]),
        commentsAndDocuments: sortByCreatedOn([
          ...commentsObject.backOffice,
          ...groupDocumentsByTime(documentsObject.backOffice),
        ]),
        comments: commentsObject,
        documents: documentsObject,
        complaintsData: [],
      };
    }),
  );
