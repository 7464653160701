import { Col, Row } from 'antd';
import { Control, useWatch } from 'react-hook-form';

import { SubclaimCreateCommentAndDocumentsForm } from '../SubclaimCreateCommentAndDocumentsForm';
import {
  AREA_ID_NAME,
  LIABLE_PARTY_TYPE_NAME,
  SubclaimForm,
} from '../SubclaimForm';
import { CLAIM_GROUP_ID_NAME, ClaimGroupControl } from '../claim-group-control';
import { useCanEditFields } from '../hooks/useCanEditFields';
import { Entity } from '../overview/types';
import { useIsClaimV2FlowEnabled } from '../hooks/useIsClaimV2FlowEnabled';
import { isTechnicalMechanicalClaim } from '../utils';
import { SubClaimV2Form } from '../SubclaimForm/SubClaimV2Form';

import { MileageControl } from './mileage-control';

import { ClaimCreateFormValues } from '.';

import { RetailClaimGroupsOptionsQuery } from '@/apollo/gql-types';

interface Props {
  claim?: Entity;
  control: Control<ClaimCreateFormValues>;
  claimGroups: RetailClaimGroupsOptionsQuery;
  orderId: string;
  errors: unknown;
}

export function ClaimCreateForm({
  claim,
  control,
  claimGroups,
  errors,
  orderId,
}: Props) {
  const [claimGroupId, areaId, liablePartyType] = useWatch({
    control,
    name: [CLAIM_GROUP_ID_NAME, AREA_ID_NAME, LIABLE_PARTY_TYPE_NAME],
  });

  const canEditFields = useCanEditFields(claim);
  const isGroupEditable = Boolean(areaId);
  const isEmptySubClaimFields = Boolean(!areaId || !liablePartyType);
  const isGroupSelected = Boolean(claimGroupId);

  const selectedClaimGroupName = claimGroups?.claimGroups?.find(
    (group) => group?.id === claimGroupId,
  )?.name;
  const isClaimV2FlowEnabled = useIsClaimV2FlowEnabled(claim);
  const canShowMileageField =
    isClaimV2FlowEnabled && isTechnicalMechanicalClaim(selectedClaimGroupName);

  return (
    <>
      <Row gutter={16}>
        <ClaimGroupControl
          claimGroups={claimGroups}
          control={control}
          disabled={
            (!isGroupEditable && isGroupSelected && !isEmptySubClaimFields) ||
            !canEditFields
          }
          isGroupSelected={isGroupSelected}
        />
        {canShowMileageField ? (
          <Col span={12} data-qa-selector="mileage">
            <MileageControl control={control} />
          </Col>
        ) : null}
      </Row>

      {isGroupSelected && (
        <>
          {isClaimV2FlowEnabled ? (
            <SubClaimV2Form
              claimGroupName={selectedClaimGroupName}
              orderId={orderId}
              allowClearAreaPartDamage
              claim={claim}
              control={control}
              claimGroupId={claimGroupId}
            />
          ) : (
            <SubclaimForm
              allowClearAreaPartDamage
              claim={claim}
              control={control}
              claimGroupId={claimGroupId}
              claimGroups={claimGroups}
            />
          )}
          <SubclaimCreateCommentAndDocumentsForm
            claim={claim}
            control={control}
            errors={errors}
          />
        </>
      )}
    </>
  );
}
