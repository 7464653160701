import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { showModal as handleCreateClaim } from '../claim-create-modal';

import cn from './styles.less';

type Props = { loading: boolean };

export const RequestNewClaimButton = ({ loading }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      disabled={loading}
      onClick={handleCreateClaim}
      className={cn.submitNewClaim}
      data-qa-selector="request-new-claim"
    >
      {t('bo.orderClaims.submitNewClaim.CTA')}
    </Button>
  );
};
