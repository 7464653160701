import { ColumnsType, ColumnType } from 'antd/lib/table';
import { CurrencyCodes } from '@retail/currency';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { SelectControlled } from '@retail/backoffice-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';
import { CostFormV2Values } from './types';
import { CostEstimateControl } from './cost-estimate-control';
import { CompensationControl } from './compensation-control';
import { isApplicableForCompensation } from './isApplicableForCompensation';

import { CostEstimateApplicability } from '@/apollo/gql-types';

export type SubClaimVm = {
  key: string;
  subClaimId: string;
  compensationReason: string;
  liablePartyOptions: Array<{ label: unknown; value: unknown }>;
  costEstimateApplicability?: CostEstimateApplicability;
  subclaimNo: string;
};

export const useSubClaimCostsColumns = ({
  control,
  currencyCode,
  setValue,
  disabled,
  shouldHideCostEstimate,
}: {
  shouldHideCostEstimate: boolean;
  disabled: boolean;
  currencyCode: CurrencyCodes;
  control: Control<CostFormV2Values>;
  setValue: UseFormSetValue<CostFormV2Values>;
}): ColumnsType<SubClaimVm> => {
  const { t } = useTranslation();
  const costs = useWatch({ control, name: 'costs' });

  return useMemo(() => {
    const columns: Array<ColumnType<SubClaimVm> & { shouldHide?: boolean }> = [
      {
        title: t('bo.orderClaims.processing.table.subclaimNumber.title'),
        dataIndex: 'subclaimNo',
      },
      {
        title: t('bo.orderClaims.warrantyType'),
        dataIndex: 'compensationReason',
        render: (warrantyType) =>
          t(`bo.orderClaims.warrantyCoverage.${warrantyType}`),
      },
      {
        width: 200,
        title: t('bo.orderClaims.costEstimateAmount'),
        shouldHide: shouldHideCostEstimate,
        render: (_value, record, index) => {
          return (
            <CostEstimateControl
              disabled={disabled}
              required={
                record.costEstimateApplicability ===
                CostEstimateApplicability.Mandatory
              }
              control={control}
              name={`costs.${index}.costEstimateAmountMinorUnits`}
              currencyCode={currencyCode}
              costValues={costs[index]}
              onCompensationAmountMinorUnitsChange={(value) =>
                setValue(`costs.${index}.compensationAmountMinorUnits`, value)
              }
            />
          );
        },
      },
      {
        title: t('bo.orderClaims.liableParty'),
        width: 350,
        render: (_value, record, index) => (
          <SelectControlled
            dropdownMatchSelectWidth={false}
            style={{ maxWidth: 300 }}
            showSearch
            data-qa-selector="liable-party-field"
            disabled={disabled}
            placeholder={t('bo.orderClaims.form.selectPlaceholder')}
            className={cn['mb-0']}
            size="large"
            onChange={(value) => {
              const compensationAllowed = isApplicableForCompensation(
                value as string,
              );
              if (!compensationAllowed) {
                setValue(`costs.${index}.compensationAmountMinorUnits`, 0);
                setValue(`costs.${index}.compensationPercentage`, 0);
              }
            }}
            options={record.liablePartyOptions}
            controllerProps={{
              name: `costs.${index}.liableParty`,
              control,
            }}
          />
        ),
      },
      {
        title: t('bo.orderClaims.compensation'),
        width: 200,
        render: (_value, record, index) => {
          const compensationAllowed = costs[index]?.liableParty
            ? isApplicableForCompensation(costs[index].liableParty)
            : true;

          return (
            <CompensationControl
              name={`costs.${index}`}
              control={control}
              shouldSyncWithCostEstimate={Boolean(
                record.costEstimateApplicability ===
                  CostEstimateApplicability.Mandatory ||
                  costs[index]?.costEstimateAmountMinorUnits,
              )}
              disabled={disabled || !compensationAllowed}
              currencyCode={currencyCode}
              costEstimateAmountMinorUnits={
                costs[index]?.costEstimateAmountMinorUnits
              }
              onCompensationAmountChange={(value) => {
                setValue(`costs.${index}.compensationAmountMinorUnits`, value);
              }}
              onCompensationPercentageChange={(value) => {
                setValue(`costs.${index}.compensationPercentage`, value);
              }}
            />
          );
        },
      },
    ];

    return columns.filter((col) => !col.shouldHide) as ColumnsType<SubClaimVm>;
  }, [
    control,
    costs,
    currencyCode,
    disabled,
    setValue,
    shouldHideCostEstimate,
    t,
  ]);
};
