// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sK6OT-xHsMPK5BvqT4Go2g\\=\\= {\n  background-color: #f9e79b;\n  padding: 5px;\n  font-size: 12px;\n}\n.msARi6vLdfPzAau2UIjzow\\=\\= {\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  gap: 6px;\n  justify-items: end;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/SubClaims/columns/styles.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;AACF;AAEA;EACE,aAAA;EACA,sCAAA;EACA,QAAA;EACA,kBAAA;AAAF","sourcesContent":[".subclaimCostsNoData {\n  background-color: #f9e79b;\n  padding: 5px;\n  font-size: 12px;\n}\n\n.distributedCosts {\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  gap: 6px;\n  justify-items: end;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subclaimCostsNoData": "sK6OT-xHsMPK5BvqT4Go2g==",
	"distributedCosts": "msARi6vLdfPzAau2UIjzow=="
};
export default ___CSS_LOADER_EXPORT___;
