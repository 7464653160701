import { Table } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Entity, ExtendedSubClaim } from '../../types';
import useComplaintsDataV2 from '../customer-complaints/hooks/useComplaintsDataV2';
import useRequiredApprovalLevel from '../compensations/hooks/useRequiredApprovalLevel';

import { Actions } from './actions';
import { useTableColumns } from './hooks/useTableColumns';
import cn from './styles.less';

import { APPROVAL_LEVEL } from '@/pages/claims/constants';
import { RetailClaimComplaintProjection } from '@/apollo/gql-types';

type Props = {
  claim: Entity;
  subClaim: ExtendedSubClaim;
  complaint: RetailClaimComplaintProjection;
  index: number;
};

const SubClaim = ({ claim, subClaim, complaint, index }: Props) => {
  const { number, createdBy } = subClaim;
  const { proofRequests = [], costs } = claim;

  const approvalLevelChecker = useRequiredApprovalLevel();

  const claimApprovedCostIds = useMemo(
    () =>
      costs
        .filter(
          (it) =>
            approvalLevelChecker(it).approvalLevelStatus ===
            APPROVAL_LEVEL.APPROVED,
        )
        .map((it) => it.id),
    [costs, approvalLevelChecker],
  );

  const columns = useTableColumns({
    claimState: claim?.state,
    claimFlowVersion: claim?.flowVersion,
    claimGroupId: claim?.claimGroupId,
    claimApprovedCostIds,
    complaint,
  });
  const complaintsData = useComplaintsDataV2({
    complaints: complaint ? [complaint] : [],
    proofRequests: proofRequests.filter(
      ({ complaintId }) => complaintId === subClaim.complaintId,
    ),
    claimGroupId: claim?.claimGroupId,
  });
  const { t } = useTranslation();

  return (
    <div>
      <div className={cn.subclaimHeader}>
        <div className={cn.subclaimHeaderTitle}>
          {`${t(
            'bo.orderClaims.processing.table.subclaimNumber.title',
          )} ${number} | ${t('bo.orderClaims.submittedBy')}: ${[
            createdBy?.firstName,
            createdBy?.lastName,
          ].join(' ')}`}
        </div>
        <Actions claim={claim} index={index} subclaim={subClaim} />
      </div>
      <div>
        <Table
          size="small"
          bordered
          rowKey="id"
          pagination={false}
          className={cn.table}
          dataSource={[{ ...subClaim, complaintsData }]}
          columns={columns}
        />
      </div>
    </div>
  );
};
export default SubClaim;
