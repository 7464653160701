// local dependencies
import { InMemoryCacheConfig } from '@apollo/client';

import { RetailClaimProjection } from './RetailClaimProjection';
import { PageDTO_RetailClaimProjection } from './PageDTO_RetailClaimProjection';
import { RetailClaimCostCommentProjection } from './RetailClaimCostCommentProjection';

const policies: InMemoryCacheConfig['typePolicies'] = {
  RetailClaimProjection,
  PageDTO_RetailClaimProjection,
  RetailClaimCostCommentProjection,
  GroupDataProjection: {
    keyFields: false,
  },
  GroupDataCarPartProjection: {
    keyFields: false,
  },
  GroupDataDamageProjection: {
    keyFields: false,
  },
  GroupDataAreaProjection: {
    keyFields: false,
  },
};

export default policies;
