import { castArray, find, flow, map } from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

import { claimGroupsSelector, claimsSelector } from '../selectors';

import { Entity } from './types';
import { adaptSubClaims } from './adaptSubClaims';

import {
  RetailClaimGroupsOptionsQuery,
  RetailClaimV2GroupsOptionsQuery,
  RetailSubClaimProjection,
  SearchRetailClaimsQuery,
} from '@/apollo/gql-types';
import { getIsTrial } from '@/utils/claims';

interface InputSelectorProps {
  claims: SearchRetailClaimsQuery;
  claimGroups: RetailClaimGroupsOptionsQuery | RetailClaimV2GroupsOptionsQuery;
}

interface OutputSelectorProps {
  entities: Entity[];
}

export default createStructuredSelector<
  InputSelectorProps,
  OutputSelectorProps
>({
  entities: createSelector(
    claimsSelector,
    claimGroupsSelector,
    (claims, claimGroups) =>
      map((claim) => {
        const claimGroup = flow(find({ id: claim.claimGroupId }))(claimGroups);
        const [areaLabel, partLabel, damageLabel] = castArray(
          claimGroup?.labels,
        );

        const isV2Flow = claim.flowVersion === 'V2';

        const getArea = (subClaim: RetailSubClaimProjection, areaId: string) =>
          isV2Flow
            ? subClaim?.groupData?.area
            : find({ id: areaId }, claimGroup?.areas);
        const getCarPart = (
          subClaim: RetailSubClaimProjection,
          areaId: string,
          carPartId: string,
        ) =>
          isV2Flow
            ? subClaim?.groupData?.area?.carPart
            : find({ id: carPartId }, getArea(subClaim, areaId)?.carParts);
        const getDamage = (
          subClaim: RetailSubClaimProjection,
          areaId: string,
          carPartId: string,
          damageId: string,
        ) =>
          isV2Flow
            ? subClaim?.groupData?.area?.carPart?.damage
            : find(
                { id: damageId },
                getCarPart(subClaim, areaId, carPartId)?.damages,
              );
        return {
          ...claim,
          isTrial: getIsTrial(claim.trialExpiredOn),
          claimGroup,
          subClaims: adaptSubClaims({
            areaLabel,
            partLabel,
            damageLabel,
            getArea,
            getCarPart,
            getDamage,
          })(claim?.subClaims),
        };
      }, claims),
  ),
});
