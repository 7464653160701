import {
  formatCurrency,
  OptionModel,
  SelectControlled,
} from '@retail/backoffice-ui';
import { CurrencyCodes } from '@retail/currency';
import { Alert, Col, Row } from 'antd';
import { memo, useEffect } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { uniq } from 'lodash/fp';

import { ExtendedSubClaim } from '../../../types';
import { OrderItemSelect } from '../cost-modal/order-item-select';
import cn from '../cost-modal/styles.less';

import { CostFormV2Values } from './types';
import { SubClaimsCostsTable } from './subclaims-costs-table';
import { SubClaimVm } from './useSubclaimCostsColumns';

import { FIELD_LABEL_COL } from '@/constants/common';
import { ClaimCostType } from '@/apollo/gql-types';

const costTypesWithOrderItem = [
  ClaimCostType.OpticalDamagesDirtyCarNotRepaired,
  ClaimCostType.CompensationWrongCarDescriptionMissingCarFeatures,
];

const adaptExtendedSubclaimsToSubclaimsVms = (
  subClaims: Array<ExtendedSubClaim>,
  costType: ClaimCostType,
  t: TFunction<'translation'>,
): SubClaimVm[] =>
  subClaims
    .map((subClaim) => ({
      subClaimId: subClaim.id!,
      compensationReason: subClaim.warrantyCoverageType,
      liablePartyOptions: subClaim.groupData.liablePartyOptions.map(
        (option) => ({
          label: t(`bo.orderClaims.submitReason.liableParty.${option.name}`),
          value: option.name,
        }),
      ),
      costEstimateApplicability: subClaim.costTypeOptions?.find(
        (option) => option.costType === costType,
      )?.costEstimateApplicability,
      subclaimNo: `${subClaim.number} ${[
        subClaim.groupData.area?.name
          ? t(
              `bo.orderClaims.submitReason.area.${subClaim.groupData.area.name}`,
            )
          : '',
        subClaim.groupData?.area?.carPart?.name
          ? t(
              `bo.orderClaims.submitReason.carParts.${subClaim.groupData?.area?.carPart?.name}`,
            )
          : '',
        subClaim.groupData?.area?.carPart?.damage?.name
          ? t(
              `bo.orderClaims.submitReason.damages.${subClaim.groupData?.area?.carPart?.damage?.name}`,
            )
          : '',
      ]
        .filter(Boolean)
        .join(' - ')}`,
    }))
    .map((item) => ({
      ...item,
      key: item.subclaimNo,
    }));

interface CostFormProps {
  setValue: UseFormSetValue<CostFormV2Values>;
  currencyCode: CurrencyCodes;
  control: Control<CostFormV2Values>;
  subClaimsToShow: ExtendedSubClaim[];
  mode?: 'view' | 'edit' | 'create';
  approvedCostMinorUnits?: number;
  costTypeOptions: OptionModel[];
}

export const CostForm = memo<CostFormProps>(
  ({
    control,
    currencyCode,
    setValue,
    subClaimsToShow,
    mode = 'edit',
    approvedCostMinorUnits,
    costTypeOptions,
  }) => {
    const { t } = useTranslation();

    const selectedCostType = useWatch({ control, name: 'costType' });
    const shouldShowOrderItemField =
      costTypesWithOrderItem.includes(selectedCostType);

    const shouldShowSubClaimsCostsTable = Boolean(selectedCostType);

    useEffect(() => {
      if (!shouldShowOrderItemField) {
        setValue('orderItemId', null);
      }
    }, [setValue, shouldShowOrderItemField]);

    const subClaimsCostDistributions = useWatch({
      control,
      name: 'costs',
    });
    const subClaimsIdsForDistribution = uniq(
      subClaimsCostDistributions
        .filter(
          (d) =>
            d.compensationAmountMinorUnits ||
            d.costEstimateAmountMinorUnits ||
            d.liableParty,
        )
        .map((cost) => cost.subClaimId),
    );
    const subClaimsWithExistingCostDistribution = subClaimsToShow.filter(
      (subClaim) =>
        subClaimsIdsForDistribution.includes(subClaim.id) &&
        subClaim.distributedCosts.length > 0,
    );

    const totalCostMinorUnits = subClaimsCostDistributions.reduce(
      (prev, curr) => prev + curr.compensationAmountMinorUnits,
      0,
    );

    return (
      <div>
        <Row>
          <Col span={24} data-qa-selector="costType">
            <SelectControlled
              disabled={mode === 'view' || mode === 'edit'}
              labelCol={FIELD_LABEL_COL}
              required
              allowClear
              label={t(
                'bo.orderClaims.processing.popUp.addCost.costAmount.title',
              )}
              options={costTypeOptions}
              placeholder={t(
                'bo.orderClaims.processing.popUp.addCost.dropdown.placeholder',
              )}
              controllerProps={{
                name: 'costType',
                control,
              }}
            />
          </Col>
        </Row>
        {shouldShowOrderItemField && (
          <Row>
            <Col span={24} data-qa-selector="order-item-id">
              <OrderItemSelect
                disabled={mode === 'view'}
                onChange={(value) =>
                  setValue('orderItemId', value, { shouldValidate: true })
                }
                // @ts-expect-error react-hook-form typings cant understand that we are passing correct control.
                control={control}
              />
            </Col>
          </Row>
        )}
        {shouldShowSubClaimsCostsTable ? (
          <Row>
            {mode === 'create' && subClaimsWithExistingCostDistribution.length
              ? subClaimsWithExistingCostDistribution.map((subClaim) => (
                  <Col
                    span={24}
                    key={subClaim.id}
                    style={{ marginBottom: '15px' }}
                  >
                    <Alert
                      type="warning"
                      className={cn.warning}
                      data-qa-selector="subclaimWithCostWarningMessage"
                      message={t(
                        'bo.orderClaims.subclaimWithCostWarningMessage',
                        { subClaimNo: subClaim.number },
                      )}
                    />
                  </Col>
                ))
              : null}
            {mode === 'edit' &&
            typeof approvedCostMinorUnits === 'number' &&
            approvedCostMinorUnits !== totalCostMinorUnits ? (
              <Col span={24} style={{ marginBottom: '15px' }}>
                <Alert
                  type="error"
                  className={cn.warning}
                  data-qa-selector="totalApprovedCostValidationMessage"
                  message={t(
                    'bo.orderClaims.costEdit.totalApprovedCostValidationMessage',
                    {
                      totalApprovedCost: formatCurrency({
                        amountMinorUnits: approvedCostMinorUnits,
                        currencyCode,
                      }),
                    },
                  )}
                />
              </Col>
            ) : null}
            <Col span={24}>
              <SubClaimsCostsTable
                disabled={mode === 'view'}
                control={control}
                setValue={setValue}
                currencyCode={currencyCode}
                data={adaptExtendedSubclaimsToSubclaimsVms(
                  subClaimsToShow,
                  selectedCostType,
                  t,
                )}
              />
            </Col>
          </Row>
        ) : null}

        <Row style={{ marginTop: '15px' }}>
          <Col span={24}>
            <Alert
              type="warning"
              className={cn.warning}
              message={t('bo.orderClaims.processing.popUp.addCost.note')}
            />
          </Col>
        </Row>
      </div>
    );
  },
);
