// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G7L7cHVPBd5wLD7vTK8vMQ\\=\\= {\n  margin-bottom: 0;\n}\n.l-0zSt-hNQhgs17eKOcyWg\\=\\= .antd-4-21-7-table-cell {\n  vertical-align: top;\n}\n.CxPqftUwxlWNuCdtE3FTNA\\=\\= {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 20px;\n}\n.NOia2S41Lx587djQ9FAGzA\\=\\= {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  min-width: 125px;\n}\n._2Y2leDdJwCFgEXe5\\+sVuIw\\=\\= > div {\n  cursor: auto !important;\n}\n.isD2AKI3DEDFpVzmjkaX9A\\=\\= {\n  display: flex;\n  justify-content: space-between;\n  border-bottom: solid #f0f0f0;\n  border-top: solid #f0f0f0;\n  background-color: #f9f9f9;\n  padding: 0 12px;\n}\n.ngB4RB9KblrKX1CAk37gFg\\=\\= {\n  font-weight: 700;\n  line-height: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/SubClaims/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAIA;EAGM,mBAAA;AAJN;AAQA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AANF;AASA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,gBAAA;AAPF;AAWE;EACE,uBAAA;AATJ;AAaA;EACE,aAAA;EACA,8BAAA;EACA,4BAAA;EACA,yBAAA;EACA,yBAAA;EACA,eAAA;AAXF;AAcA;EACE,gBAAA;EACA,iBAAA;AAZF","sourcesContent":["@import '../../../../../styles/constants.less';\n\n.title {\n  margin-bottom: 0;\n}\n\n.table {\n  :global {\n    .@{antd-version}-table-cell {\n      vertical-align: top;\n    }\n  }\n}\n.cta {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 20px;\n}\n\n.actions {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  min-width: 125px;\n}\n\n.panel {\n  & > div {\n    cursor: auto !important;\n  }\n}\n\n.subclaimHeader {\n  display: flex;\n  justify-content: space-between;\n  border-bottom: solid #f0f0f0;\n  border-top: solid #f0f0f0;\n  background-color: #f9f9f9;\n  padding: 0 12px;\n}\n\n.subclaimHeaderTitle {\n  font-weight: 700;\n  line-height: 40px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "G7L7cHVPBd5wLD7vTK8vMQ==",
	"table": "l-0zSt-hNQhgs17eKOcyWg==",
	"cta": "CxPqftUwxlWNuCdtE3FTNA==",
	"actions": "NOia2S41Lx587djQ9FAGzA==",
	"panel": "_2Y2leDdJwCFgEXe5+sVuIw==",
	"subclaimHeader": "isD2AKI3DEDFpVzmjkaX9A==",
	"subclaimHeaderTitle": "ngB4RB9KblrKX1CAk37gFg=="
};
export default ___CSS_LOADER_EXPORT___;
