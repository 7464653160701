import { Modal, Button, Card, Alert, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { makeVar, useReactiveVar } from '@apollo/client';
import { memo, SyntheticEvent, useCallback, useEffect } from 'react';
import { FormProvider, useForm, FieldValues } from 'react-hook-form';

import { Entity } from '../overview/types';
import { ClaimGroupControl, CLAIM_GROUP_ID_NAME } from '../claim-group-control';

import { useUpdateClaimGroup } from './useUpdateClaimGroup';
import cn from './styles.less';

import { RetailClaimGroupsOptionsQuery } from '@/apollo/gql-types';

const modalVars = makeVar<{ visible?: boolean; claim?: Entity }>({});

export const hideModal = () => modalVars({});
export const showModal = (claim: Entity) => modalVars({ claim, visible: true });

interface IProps {
  claimGroups: RetailClaimGroupsOptionsQuery;
}

export const SubclaimEditGroupModal = memo<IProps>(({ claimGroups }) => {
  const { visible, claim } = useReactiveVar(modalVars);
  const { t } = useTranslation();
  const updateClaimGroup = useUpdateClaimGroup();

  const form = useForm<FieldValues>({
    defaultValues: {
      [CLAIM_GROUP_ID_NAME]: claim?.claimGroupId,
    },
    mode: 'all',
  });

  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
    reset,
  } = form;

  useEffect(
    () =>
      visible &&
      reset({
        claimGroupId: claim?.claimGroupId,
      }),
    [visible, reset, claim?.claimGroupId],
  );

  const onHandleSubmit = useCallback(
    async (event: SyntheticEvent) => {
      await handleSubmit(({ claimGroupId }) =>
        updateClaimGroup({
          ...claim,
          claimGroupId,
        }),
      )(event);

      hideModal();
    },
    [claim, handleSubmit, updateClaimGroup],
  );

  return (
    <Modal
      centered
      visible={visible}
      width={500}
      onCancel={hideModal}
      className={cn.modal}
      title={<span>{t('bo.orderClaims.form.editClaimGroup')}</span>}
      footer={
        <>
          <Button type="default" onClick={hideModal}>
            <span data-qa-selector="cancel-edit-claim-group">
              {t('bo.orderClaims.form.cancelBtnText')}
            </span>
          </Button>
          <Button
            disabled={!isDirty}
            type="primary"
            loading={isSubmitting}
            onClick={onHandleSubmit}
          >
            <span data-qa-selector="edit-claim-group">
              {t('bo.orderClaims.form.saveBtnText')}
            </span>
          </Button>
        </>
      }
    >
      <Card>
        <FormProvider {...form}>
          <form onSubmit={onHandleSubmit}>
            <Row>
              <ClaimGroupControl claimGroups={claimGroups} control={control} />
            </Row>

            {isDirty && (
              <Alert
                message={t('bo.orderClaims.form.editClaimGroup.warning')}
                type="warning"
                showIcon
              />
            )}
          </form>
        </FormProvider>
      </Card>
    </Modal>
  );
});
