import { Control, useWatch } from 'react-hook-form';
import { Space } from 'antd';
import {
  CurrencyCodes,
  getCurrencySymbolByCode,
  getFormatInputFromMinorUnits,
  getParseInputToMinorUnits,
} from '@retail/currency';
import { identity } from 'lodash/fp';

import { CostFormV2Values } from './types';

import { PriceInputControlled } from '@/components/form/price';
import {
  allowOnlyNumbersAndPunctuation,
  getPercentageOfValue,
} from '@/pages/claims/utils';

type CostValues = CostFormV2Values['costs'][number];

type CompensationControlProps = {
  disabled: boolean;
  shouldSyncWithCostEstimate?: boolean;
  control: Control<CostFormV2Values>;
  name: `costs.${number}`;
  currencyCode: CurrencyCodes;
  costEstimateAmountMinorUnits: number;

  onCompensationAmountChange: (
    amount: CostValues['compensationAmountMinorUnits'],
  ) => void;
  onCompensationPercentageChange: (
    percentage: CostValues['compensationPercentage'],
  ) => void;
};

export const CompensationControl = ({
  disabled,
  shouldSyncWithCostEstimate,
  control,
  name,
  currencyCode,
  costEstimateAmountMinorUnits,

  onCompensationAmountChange,
  onCompensationPercentageChange,
}: CompensationControlProps) => {
  const compensationAmountMinorUnits = useWatch({
    control,
    name: `${name}.compensationAmountMinorUnits`,
  });
  const status =
    compensationAmountMinorUnits > costEstimateAmountMinorUnits
      ? 'error'
      : undefined;

  return (
    <Space direction="vertical">
      <PriceInputControlled
        data-qa-selector={`${name}.compensationAmountMinorUnits`}
        disabled={disabled}
        format={(value) => {
          const formattedValue = getFormatInputFromMinorUnits(currencyCode)(
            value as number,
          );

          return Number((formattedValue || 0).toFixed(2));
        }}
        parse={(value) =>
          getParseInputToMinorUnits(currencyCode)(value as number) || 0
        }
        wrapperStyles={{ marginBottom: 0 }}
        type="number"
        size="large"
        status={status}
        controllerProps={{
          control,
          name: `${name}.compensationAmountMinorUnits`,
        }}
        onKeyPress={allowOnlyNumbersAndPunctuation}
        onBlur={() => {
          if (
            shouldSyncWithCostEstimate &&
            compensationAmountMinorUnits > costEstimateAmountMinorUnits
          ) {
            onCompensationAmountChange(costEstimateAmountMinorUnits);
          }
        }}
        onChange={(compensationAmountMinorUnits) => {
          if (typeof compensationAmountMinorUnits === 'number') {
            const percentage =
              costEstimateAmountMinorUnits === 0
                ? 0
                : +Math.min(
                    (compensationAmountMinorUnits /
                      costEstimateAmountMinorUnits) *
                      100,
                    100,
                  ).toFixed(2);

            onCompensationPercentageChange(percentage);
          }
        }}
        addonBefore={
          <span style={{ fontFamily: 'monospace' }}>
            {getCurrencySymbolByCode(currencyCode)}
          </span>
        }
      />
      <PriceInputControlled
        data-qa-selector={`${name}.compensationPercentage`}
        disabled={disabled || !shouldSyncWithCostEstimate}
        wrapperStyles={{ marginBottom: 0 }}
        type="number"
        size="large"
        parse={identity}
        format={identity}
        max={100}
        onKeyPress={allowOnlyNumbersAndPunctuation}
        controllerProps={{
          control,
          name: `${name}.compensationPercentage`,
        }}
        onChange={(compensationPercentage) => {
          if (typeof compensationPercentage === 'number') {
            // when the compensation percentage is changed then we should recalculate compensation amount
            const compensationAmount = getPercentageOfValue(
              costEstimateAmountMinorUnits,
              compensationPercentage,
              0,
            );

            onCompensationAmountChange(compensationAmount);
          }
        }}
        addonBefore={<span style={{ fontFamily: 'monospace' }}>%</span>}
      />
    </Space>
  );
};
