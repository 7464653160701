import { OptionModel } from '@retail/backoffice-ui';
import {
  defaults,
  filter,
  find,
  flow,
  get,
  getOr,
  map,
  sortBy,
  uniqBy,
} from 'lodash/fp';
import { TFunction } from 'react-i18next';
import { createSelector, createStructuredSelector } from 'reselect';

import { claimGroupsSelector } from '../selectors';

import {
  RetailClaimAreaProjection,
  RetailClaimGroupsOptionsQuery,
  RetailClaimV2GroupsOptionsQuery,
} from '@/apollo/gql-types';

interface SelectorInput {
  claimGroups: RetailClaimGroupsOptionsQuery | RetailClaimV2GroupsOptionsQuery;
  claimGroupId: string;
  damageId: string;
  areaId: string;
  carPartId: string;
  t: TFunction<'translation', undefined>;
}

interface SelectorOutput {
  labels: string[];
  areaOptions: OptionModel[];
  liablePartyOptions: OptionModel[];
  damageOptions: OptionModel[];
  carPartOptions: OptionModel[];
  carPartAllOptions: (OptionModel & { areaId: string })[];
}

const translatorSelector = ({ t }: SelectorInput) => t;

const claimGroupSelector = createSelector(
  claimGroupsSelector,
  ({ claimGroupId }: SelectorInput) => claimGroupId,
  (data, id) => find({ id })(data),
);

const liablePartyOptions = createSelector(
  claimGroupSelector,
  ({ t }: SelectorInput) => t,
  (data, t) =>
    flow(
      get(['liableParties']),
      map(({ value, name }) => ({
        value,
        label: t(name),
      })),
      sortBy<OptionModel>('label'),
    )(data),
);

const areasOptionsSelector = createSelector(
  claimGroupSelector,
  /** @todo check [] */
  getOr<RetailClaimAreaProjection[]>([], ['areas']),
);

const carPartOptionsSelector = createSelector(
  areasOptionsSelector,
  ({ areaId }: SelectorInput) => areaId,
  (areas, areaId) =>
    flow(find({ id: areaId }), get('carParts'), defaults([]))(areas),
);

const carPartAllOptionsSelector = createSelector(
  areasOptionsSelector,
  translatorSelector,
  (areas, t) =>
    uniqBy(
      'label',
      areas.reduce<SelectorOutput['carPartAllOptions']>((all, area) => {
        all.push(
          ...area.carParts
            .filter((it) => !it.deleted)
            .map((it) => ({
              value: it.id,
              label: t(it.name),
              areaId: area.id,
            })),
        );

        return all;
      }, []),
    ),
);

const damageOptionsSelector = createSelector(
  carPartOptionsSelector,
  ({ carPartId }: SelectorInput) => carPartId,
  (carParts, carPartId) =>
    flow(find({ id: carPartId }), get('damages'), defaults([]))(carParts),
);

const mapOptions = <T extends { id: string; name: string; deleted?: boolean }>(
  data: T[],
  t: TFunction<'translation', undefined>,
) =>
  flow(
    filter(({ deleted }) => !deleted),
    map(({ id: value, name }: T) => ({ value, label: t(name) })),
    sortBy<OptionModel>('label'),
  )(data);

const labelsSelector = createSelector(
  claimGroupSelector,
  translatorSelector,
  (data, t) =>
    flow(
      get(['labels']),
      map((label) => t(label as string)),
    )(data),
);

export default createStructuredSelector<SelectorInput, SelectorOutput>({
  labels: flow(labelsSelector),
  liablePartyOptions,
  areaOptions: createSelector(
    areasOptionsSelector,
    translatorSelector,
    mapOptions,
  ),
  carPartOptions: createSelector(
    carPartOptionsSelector,
    translatorSelector,
    mapOptions,
  ),
  carPartAllOptions: carPartAllOptionsSelector,
  damageOptions: createSelector(
    damageOptionsSelector,
    translatorSelector,
    mapOptions,
  ),
});
