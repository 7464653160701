import { makeVar, useReactiveVar } from '@apollo/client';
import { Button, Card, Modal } from 'antd';
import { memo, SyntheticEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SubclaimForm } from '../SubclaimForm';
import { SubClaimV2Form } from '../SubclaimForm/SubClaimV2Form';
import { useIsClaimV2FlowEnabled } from '../hooks/useIsClaimV2FlowEnabled';
import { ExtendedSubClaim, Entity } from '../overview/types';

import {
  RetailClaimGroupsOptionsQuery,
  RetailSubClaimUpdateProjectionInput,
  useUpdateRetailOrderSubClaimMutation,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

export const manageClaimSubmissionModal = makeVar<{
  visible?: boolean;
  subclaim?: ExtendedSubClaim;
  claim?: Entity;
}>({});

export const hideModal = () => manageClaimSubmissionModal({});
export const showModal = (claim: Entity, subclaim: ExtendedSubClaim) =>
  manageClaimSubmissionModal({
    claim,
    subclaim,
    visible: true,
  });

interface Props {
  claimGroups: RetailClaimGroupsOptionsQuery;
  orderId: string;
}

export const SubclaimEditModal = memo<Props>(({ claimGroups, orderId }) => {
  const { visible, subclaim, claim } = useReactiveVar(
    manageClaimSubmissionModal,
  );
  const { t } = useTranslation();

  const [updateSubclaim] = useUpdateRetailOrderSubClaimMutation({
    ...REQUEST_BASE_OPTIONS,
    refetchQueries: ['SearchRetailClaims'],
  });

  const form = useForm<RetailSubClaimUpdateProjectionInput>({
    defaultValues: {},
    mode: 'all',
  });
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
  } = form;

  useEffect(
    () =>
      reset({
        areaId: subclaim?.areaId,
        carPartId: subclaim?.carPartId,
        damageId: subclaim?.damageId,
        liablePartyType: subclaim?.liablePartyType,
        timeDependencyType: subclaim?.timeDependencyType,
        workType: subclaim?.workType,
        warrantyCoverageType: subclaim?.warrantyCoverageType,
      }),
    [subclaim, reset],
  );

  useEffect(() => {
    if (!visible) {
      reset({});
    }
  }, [reset, visible]);

  const disabled = !isValid;

  async function onHandleSubmit(event: SyntheticEvent) {
    if (disabled) {
      return;
    }

    await handleSubmit((retailClaim) => {
      updateSubclaim({
        variables: {
          subClaimId: subclaim?.id,
          retailClaim,
        },
      });
    })(event);

    hideModal();
  }

  const isClaimV2FlowEnabled = useIsClaimV2FlowEnabled(claim);

  return (
    <Modal
      centered
      visible={visible}
      destroyOnClose
      width={1000}
      onCancel={hideModal}
      data-qa-selector="subclaim-edit-modal"
      title={
        <strong>
          {t('bo.orderClaims.processing.table.reasonsAndLiableParty.title')}
        </strong>
      }
      footer={
        <>
          <Button type="default" onClick={hideModal}>
            <span data-qa-selector="cancel-subclaim-updating">
              {t('bo.orderClaims.form.cancelBtnText')}
            </span>
          </Button>
          <Button
            type="primary"
            loading={isSubmitting}
            disabled={disabled}
            onClick={onHandleSubmit}
          >
            <span data-qa-selector="update-subclaim">
              {t('bo.orderClaims.form.saveBtnText')}
            </span>
          </Button>
        </>
      }
    >
      <Card
        title={
          <>
            <span>{`${t('bo.orderClaims.form.sectionTitle')}: `}</span>
            <strong>{subclaim?.number}</strong>
          </>
        }
      >
        <FormProvider {...form}>
          <form onSubmit={onHandleSubmit}>
            {isClaimV2FlowEnabled ? (
              <SubClaimV2Form
                orderId={orderId}
                claim={claim}
                subClaim={subclaim}
                control={control}
                claimGroupName={claim?.claimGroup?.name}
                claimGroupId={claim?.claimGroupId}
              />
            ) : (
              <SubclaimForm
                claim={claim}
                control={control}
                claimGroupId={claim?.claimGroupId}
                claimGroups={claimGroups}
              />
            )}
          </form>
        </FormProvider>
      </Card>
    </Modal>
  );
});
