import { useMemo } from 'react';

import { claimGroupsSelector } from '../../../../../selectors';

import {
  RetailClaimAreaProjection,
  RetailClaimCarPartProjection,
  RetailClaimDamageProjection,
  RetailClaimGroupProjection,
  RetailClaimGroupsOptionsQuery,
  RetailClaimV2GroupsOptionsQuery,
} from '@/apollo/gql-types';

interface AreaIdProps {
  areaId: string;
}
interface CarPartIdProps extends AreaIdProps {
  carPartId: string;
}

interface ClaimGroupsDataProps {
  claimGroupMap: Record<string, RetailClaimGroupProjection>;
  areas: RetailClaimAreaProjection[];
}
interface ClaimGroupDataMapProps {
  areasMap: Record<string, RetailClaimAreaProjection & AreaIdProps>;
  carPartsMap: Record<string, RetailClaimCarPartProjection & AreaIdProps>;
  damagesMap: Record<string, RetailClaimDamageProjection & CarPartIdProps>;
}

interface IUseClaimGroupDataMapProps {
  claimGroups: RetailClaimGroupsOptionsQuery | RetailClaimV2GroupsOptionsQuery;
}

const useClaimGroupDataMap = ({ claimGroups }: IUseClaimGroupDataMapProps) =>
  useMemo(() => {
    const claimGroupsData = claimGroupsSelector({
      claimGroups,
    });

    const { claimGroupMap, areas } = claimGroupsData.reduce(
      (acc, claimGroup) => {
        const { id, areas } = claimGroup;
        acc.claimGroupMap[id] = claimGroup;
        acc.areas = [...acc.areas, ...areas];

        return acc;
      },
      {
        claimGroupMap: {},
        areas: [],
      } as ClaimGroupsDataProps,
    );

    const claimGroupDataMap = areas.reduce(
      (acc, area) => {
        const areaId = area.id;
        acc.areasMap[area.id] = {
          ...area,
          areaId,
        };

        area.carParts.reduce((acc, carPart) => {
          const carPartId = carPart.id;
          acc.carPartsMap[carPart.id] = {
            ...carPart,
            areaId,
          };

          carPart.damages.reduce((acc, damage) => {
            acc.damagesMap[damage.id] = {
              ...damage,
              carPartId,
              areaId,
            };

            return acc;
          }, acc);

          return acc;
        }, acc);

        return acc;
      },
      {
        areasMap: {},
        carPartsMap: {},
        damagesMap: {},
      } as ClaimGroupDataMapProps,
    );

    return {
      claimGroupMap,
      ...claimGroupDataMap,
    };
  }, [claimGroups]);

export default useClaimGroupDataMap;
