// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZKBjs0LABrmFsDNE7DQi1w\\=\\= {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  gap: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/compensations/actions/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,SAAA;AACF","sourcesContent":[".actions {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  gap: 12px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "ZKBjs0LABrmFsDNE7DQi1w=="
};
export default ___CSS_LOADER_EXPORT___;
