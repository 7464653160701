import { PlusOutlined } from '@ant-design/icons';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { CLAIM_STATES } from 'src/pages/claims/constants';

import { useIsSubClaimDisabled } from '../../../../hooks/useIsSubClaimDisabled';
import { showModal as showSubclaimCreateModal } from '../../../../SubClaimCreateModal';
import { Entity } from '../../../types';
import {
  showModal as showSubclaimCostModal,
  SubClaimModal,
} from '../../SubClaimModal';

import {
  EDIT_CLAIM_PERMISSIONS,
  EDIT_CLOSED_CLAIM_PERMISSIONS,
} from './constants';
import cn from './styles.less';

import { FEATURE } from '@/constants/features';
import { DOMAINS } from '@/constants/permissions';
import { useFeatures } from '@/providers/FeaturesProvider';

interface Props {
  claim: Entity;
  isAddSubClaimCostDisabled: boolean;
}

export function Footer({ claim, isAddSubClaimCostDisabled }: Props) {
  const { t } = useTranslation();
  const features = useFeatures();
  const isSubClaimDisabled = useIsSubClaimDisabled(claim);

  const hasClaimsEditOrderClaimsClosedPermissions = useCheckPermissions({
    allow: EDIT_CLOSED_CLAIM_PERMISSIONS,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  const hasEditClaimPermissions = useCheckPermissions({
    allow: EDIT_CLAIM_PERMISSIONS,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  const isClosed = claim?.state === CLAIM_STATES.CLOSED;

  const hasEditExtendedPermission = isClosed
    ? hasClaimsEditOrderClaimsClosedPermissions
    : hasEditClaimPermissions;

  return (
    <div className={cn.footer}>
      <SubClaimModal claim={claim} />

      {claim?.flowVersion !== 'V2' &&
        features[FEATURE.RETAIL_CLAIM_COST_PROCESSING] && (
          <div className={cn.cta}>
            <Button
              disabled={isAddSubClaimCostDisabled}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => showSubclaimCostModal()}
              data-qa-selector="add_subclaim_cost"
            >
              {t('bo.orderClaims.processing.addSubclaimCosts.CTA')}
            </Button>
          </div>
        )}

      {hasEditExtendedPermission.isAllowed ? (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          disabled={isSubClaimDisabled}
          data-qa-selector="add-subclaim"
          onClick={() => showSubclaimCreateModal(claim)}
        >
          {t('bo.orderClaims.addSubClaimCTA')}
        </Button>
      ) : null}
    </div>
  );
}
